@charset "utf-8";

@media screen and (max-width: 1440px) {
  .width14,
  .sect1Re .subTit,
  .indicaotr,
  .sect06Re .storyWrap {
    width: 1100px;
  }
  .storyCon .storyImg {
    width: auto;
  }
  /* 메인홈 1440 */
  .sect1Re .mainTit h2 {
    font-size: 6.4rem;
    line-height: 1.3;
  }
  .sect2Re .mainTit h2 {
    font-size: 5.6rem;
  }
  .animated-title {
    font-size: 4rem;
  }
  .sect3Re .mainTit h2,
  .sect05Re .mainTit h2 {
    font-size: 3.7rem;
  }
  .sect4Re .mainTit h2{
    font-size: 3.2rem;
  }
  .sect06Re .mainTit h2 {
    font-size: 4rem;
    width: 230px;
  }
  .sect05Re .xBoxWrap .xBox .appTit h3,
  .sect2Re ul li p,
  .sect2Re .mainTit p {
    font-size: 2.4rem;
  }
  .sect1Re .subTit p,
  .sect3Re .mainTit h2 + p,
  .sect05Re .mainTit h2 + div p,
  .sect06Re .mainTit p {
    font-size: 2rem;
  }
  .sect1Re .subTit span {
    font-size: 1.4rem;
  }
  section:first-child {
    padding: 200px 0 100px;
  }
  .sect2Re ul {
    margin: 60px auto;
  }
  .sect2Re ul li {
    padding: 40px 60px;
  }
  .sect05Re .mainTit h2 + div {
    margin: 70px auto 50px;
  }
  .sect4Re ul {
    margin-top: 40px;
  }
  .storyCon h3 + p {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  /* 기획서 1440 */
  .appplanWrap .bottom {
    height: 60px;
  }
  .sidebarWrap button.back,
  .tab_content .tit,
  .sidebarWrap button.pdfBtn {
    font-size: 1.4rem;
  }
  .tab_item2 button {
    font-size: 1.3rem;
  }
  .sidebarWrap {
    width: 15vw;
  }
  .tab_content {
    padding: 20px 40px;
    margin-left: 15vw;
  }
  .tab_content .d-flex {
    gap: 20px;
  }
  .sidebarWrap .pageList {
    height: 200px;
  }
  .footer_left .left {
    gap: 50px;
  }
  .footer_left .right .content {
    width: 150px;
  }
  /* 서비스 소개 1440 */
  .serviceInfo .sectTit h2 {
    font-size: 4rem;
  }
  .serviceInfo .sectTit p,
  .whyWrap p,
  .planWrap ul li,
  .addBox .addTit h4 {
    font-size: 1.8rem;
  }
  .container .card {
    width: 370px;
    height: 270px;
  }
  .container .card:before {
    clip-path: circle(150px at 90% 0%);
  }
  .container .card:hover:before {
    clip-path: circle(230px at 90% -10%);
  }
  .container .card .imgBx img {
    width: 150px;
  }
  .container .card:hover .contentBx {
    height: 90px;
  }
  .container .card .contentBx h2 {
    font-size: 2.4rem;
  }
  .container .card .contentBx .txt {
    font-size: 1.5rem;
    padding-top: 5px;
  }.container .card:hover .imgBx {
    transform: translateY(-7%);
  }
  .container .card:after {
    font-size: 9rem;
    left: -10%;
  }
  .whyWrap h3 {
    font-size: 3.2rem;
  }
  .privilegeWrap ul li h3 {
    font-size: 2rem;
  }
  .privilegeWrap ul li p {
    font-size: 1.6rem;
  }
  .privilegeWrap ul li {
    height: 230px;
    padding: 35px 44px;
  }
  /* 앱생성가이드 1440  */
  .guideBox {
    width: 100%;
    padding: 120px;
  }
  .guideWrap .sectTit h2,
  .guideBox h3 {
    font-size: 4rem;
  }
  .guideBox p,
  .guideBox .step {
    font-size: 1.8rem;
  }
  .guideBox:first-child {
    background-size: 60%;
  }
  .guideBox:nth-child(2) {
    background-size: 45%;
  }
  .guideBox:nth-child(3) {
    background: #fff url(http://localhost:3000/static/media/guideimg03.c971a1e3cca0a76becd2.png) no-repeat right 120px bottom 50%;
    background-size: 25%;
  }
  .guideBox:nth-child(4) {
    background: #fff url(http://localhost:3000/static/media/guideimg04.31202cfbb40ec6e1fa58.png) no-repeat right 130px bottom 50%;
    background-size: 30%;
  }
  .guideBox:nth-child(5) {
    background: #fff url(http://localhost:3000/static/media/guideimg05.320dbb99f75fbc594d3b.png) no-repeat right 130px bottom 50%;
    background-size: 30%;
  }
  .guideBox:nth-child(6) {
    background: #fff url(http://localhost:3000/static/media/guideimg06.e062588df44f41482a45.png) no-repeat right 130px bottom 50%;
    background-size: 20%;
  }
  .userLeft .bBox {
    width: 490px;
  }
  /* 앱생성 */
  .checkListCon .wrap .leftCon {
    padding: 180px 100px;
  }
  .checkListCon .wrap .centerCon {
    padding: 150px 100px;
  }
  .templateImg {
    width: 255px;
    height: 565px;
  }
  .templateImg .slick-slider {
    width: 235px;
    height: 545px;
  }
  .similarWrap {
    flex-wrap: wrap;
  }
  .checkListReWrap .stepWrap {
    gap: 100px;
  }
  .checkListReWrap .stepWrap::before {
    width: 90%;
  }
  .checkListReWrap .stepWrap.step2::after {
    left:5%;
    width: 200px;
  }
  .checkListReWrap .stepWrap.step3::after {
    left:5%;
    width: 363px;
  }
  .checkListReWrap .stepWrap.step4::after {
    width: 555px;
  }
  .checkListReWrap .stepWrap.step5::after {
    width: 735px;
  }
  .checkListReWrap .stepWrap.step6::after {
    width: 910px;
  }
}

@media screen and (max-width: 1280px) {
  .footer_left .footer_logo {
    width: 90px;
  }
  .footer_left .content {
    width: 360px;
  }
  .footer_top {
    flex-wrap: wrap;
  }
  .width14,
  .sect1Re .subTit,
  .indicaotr,
  .sect06Re .storyWrap  {
    width: 900px;
  }
  .sect1Re .subTit {
    margin: 150px auto 0;
  }
  .navbar_wrap, .navbar_menu  {
    gap: 25px;
  }
  #topBtn {
   right: 80px;
   bottom: 60px;
  }
  #inquiryBtn{
    right: 80px;
    bottom: 130px;
  }
  .storyCon .storyImg {
    width: 100%;
    height: 160px;
  }
  /* 서비스 소개 1280 */
  .serviceInfo .sectTit h2 {
    font-size: 3.2rem;
  }
  .serviceInfo .sectTit p,
  .whyWrap p,
  .planWrap ul li {
    font-size: 1.6rem;
  }
  .container .card {
    width: 300px;
    height: 200px;
  }
  .container .card:before {
    clip-path: circle(120px at 90% 0%);
  }
  .container .card:hover:before {
    clip-path: circle(180px at 90% -10%);
  }
  .container .card .imgBx img {
    width: 100px;
  }
  .container .card:hover .contentBx {
    height: 90px;
  }
  .container .card .contentBx h2 {
    font-size: 2rem;
  }
  .container .card .contentBx .txt {
    font-size: 1.5rem;
    padding-top: 5px;
  }
  .container .card:hover .imgBx {
    transform: translateY(-25%);
  }
  .container .card:after {
    font-size: 7rem;
    left: 0;
  }
  .whyWrap h3 {
     font-size: 2.8rem;
  }
  .privilegeWrap ul li h3,
  .addCon h5,
  .addBox .addTit h4,
  .additionWrap .tab__item {
    font-size: 1.8rem;
  }
  .privilegeWrap ul li p {
    font-size: 1.6rem;
  }
  .privilegeWrap ul li .tag {
    font-size: 1.4rem;
  }
  .privilegeWrap ul li {
    width: 440px;
    height: 200px;
    padding: 30px 40px;
  }
  .planWrap ul li:last-child {
    width: auto;
  }
  /* 앱생성가이드 1280  */
  .guideBox {
    height: 580px;
    padding: 100px;
  }
  .guideWrap .sectTit h2,
  .guideBox h3 {
    font-size: 3.2rem;
  }
  .guideTit img {
    width: 40px;
  }
  .guideBox p,
  .guideBox .step {
    font-size: 1.6rem;
  }
  /* Story 1028 */
  .tab__content-wrapper ul li .storyImg {
    width: 430px;
    height: 255px;
  }
  .tab__content-wrapper ul li h3 {
    width: 420px;
  }
  .sectTit + .d-flex {
    flex-wrap: wrap;
  }
  .storydetail h2 {
    font-size: 4rem;
  }
  /* mypage 1280 */
  .mypageWrap .item {
    padding: 40px;
  }
  .mypageWrap .item2, 
  .mypageWrap .item3 {
    min-height: 250px;
  }
  .mypageWrap .item2Btn {
    bottom: 40px;
  }
  .QRBtnWrap {
    right: 40px;
    bottom: 40px;
  }
  .mypageWrap h2 {
    font-size: 4rem;
  }
  .userLeft .bBox {
    width: 392px;
  }
  /* 체크리스트 */
  .similarWrap li {
    width: 300px;
  }
  .checkListReWrap .stepWrap {
    gap: 60px;
  }
  .checkListReWrap .stepWrap::before {
    width: 88%;
  }
  .checkListReWrap .stepWrap.step2::after {
    left: 6%;
    width: 160px;
  }
  .checkListReWrap .stepWrap.step3::after {
    left: 6%;
    width: 285px;
  }
  .checkListReWrap .stepWrap.step4::after {
    left: 6%;
    width: 420px;
  }
  .checkListReWrap .stepWrap.step5::after {
    left: 6%;
    width: 545px;
  }
  .checkListReWrap .stepWrap.step6::after {
    left: 6%;
    width: 685px;
  }
  .leftCon .sbox div input[type="text"],
  .sbox .tit {
    width: 400px;
  }
}
@media screen and (max-width: 1024px) {
  section {
    padding: 150px 0;
  }
  section:first-child {
    padding: 150px 0 100px;
  }
  .sect4Re {
    padding: 0 0 150px;
  }
  .width14,
  .sect1Re .subTit,
  .indicaotr,
  .sect06Re .storyWrap  {
    width: 800px;
  }
  .navbar {
    gap: 30px;
  }
  .navbar_logo,
  nav .right {
    min-width: 100px;
  }
  .sect1Re .mainTit h2 {
    font-size: 5.6rem;
  }
  .sect2Re .mainTit h2 {
    font-size: 4.8rem;
  }
  .sect3Re .mainTit h2 + p {
    margin: 40px 0;
  }
  .sect3Re .mainTit h2,
  .sect05Re .mainTit h2,
  .sect4Re .mainTit h2 {
    font-size: 3rem;
  }
  .sect06Re .mainTit h2 {
    width: 185px;
    font-size: 3.2rem;
  }
  .sect05Re .xBoxWrap .xBox .appTit h3, 
  .sect2Re ul li p, 
  .sect2Re .mainTit p {
    font-size: 2rem;
  }
  .sect2Re ul li {
    padding: 30px 50px;
  }
  .sect3Re ul {
    width: 800px;
  }
  .sect3Re ul li {
    width: 380px;
    height: auto;
  }
  .sect3Re ul li img {
    width: 100px;
  }
  .sect3Re .nextarrow {
    margin: 100px 0 0;
  }
  .sect4Re ul li h3 {
    height: 90px;
    margin: 30px 0 20px;
    font-size: 2rem;
  }
  .sect4Re ul li p {
    margin: 20px 0;
  }
  .sect4Re ul li {
    width: 250px;
    height: 450px;
  }
  .sect4Re ul li .circleImg {
    width: 130px;
    height: 130px;
  }
  .sect05Re .mainTit h2 + div {
    margin: 50px auto;
  }
  .sect2Re h2::before,
  .sect3Re .mainTit h2::before,
  .sect05Re .mainTit h2::before {
    font-size: 2.8rem;
  }
  .sect05Re .xBoxWrap {
    gap: 20px;
  }
  .sect06Re .mainTit p {
    margin: 20px 0 30px;
  }
  .sect05Re .xBoxWrap .xBox {
    width: 390px;
    height: auto;
    padding: 40px;
  }
  .sect05Re .xBoxWrap .xBox .d-flex p {
    font-size: 1.6rem;
  }
  .sect05Re .xBoxWrap .xBox .d-flex {
    margin-bottom: 20px;
    flex-direction:column-reverse;
    flex-wrap: wrap;
  }
  .sect05Re .xBoxWrap .xBox .d-flex img {
    width: 100px;
    gap: 100px;
    object-fit: contain;
  }
  .sect05Re .xBoxWrap .xBox .appTit {
    width: 100%;
    margin-top: 20px;
    border-radius: 16px;
  }
  .sect05Re .xBoxWrap .xBox .appTit h3 {
    padding: 10px 35px;
  }
  .sect05Re .xBoxWrap .xBox ul {
    padding-top: 20px;
  }
  .footer_top {
    flex-wrap: wrap;
  }
  /* 기획서 1024 */
  .appplanWrap .bottom {
    height: 50px;
  }
  .tab_content .tit {
    font-size: 1.6rem;
  }
  .sidebarWrap button.back {
    font-size: 1.6rem;
  }
  /* 서비스 소개 1024 */
  .infoWrap ul {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
  .container {
    margin-top: 20px;
  }
  .container .card {
    width: 450px;
    height: 350px;
  }
  .container .card:after {
    font-size: 12rem;
    left: -20%;
  }
  .container .card .imgBx {
    transform: translateY(-45%);
  }
  .container .card .imgBx img {
    width:180px;
  }
  .container .card:hover .imgBx {
    transform: translateY(0);
  }
  .container .card .contentBx {
    height: 70px;
  }
  .container .card .contentBx h2 {
    font-size: 2.8rem;
  }
  .container .card .contentBx .txt {
    padding-top: 10px;
  }
  .container .card:hover .contentBx {
    height: 120px;
  }
  .whyWrap h3 {
    font-size: 2.4rem;
  }
  .planWrap {
    padding: 150px 30px;
  }
  .planWrap ul li {
    white-space: wrap;
  }
  .additionWrap .tab,
  .additionWrap .tab__item {
    width: 180px;
  }
  .additionWrap .tabWrap {
    gap: 10px;
  }
  .addBox .addTit {
    min-width: 670px;
    gap: 30px;
  }
  .addBox .addTit p {
    width: 480px;
  }
  .addCon .conBox p {
    width: 95%;
  }
  .serviceInfo .sectTit h2 {
    margin-bottom: 10px;
  }
  /* 앱생성가이드 1024  */
  .guideBox {
    width: 100%;
    height: 480px;
    padding: 80px;
  }
  .guideCon {
    margin-top: 30px;
  }
  .guideWrap .width14 {
    padding: 0;
  }
  .guideWrap .sectTit h2,
  .guideBox h3 {
    font-size: 3.2rem;
  }
  .guideTit img {
     width: 40px;
  }
  .guideBox p,
  .guideBox .step {
    font-size: 1.6rem;
  }
  .guideBox:first-child {
    background-size: 50%;
  }
  .guideBox:nth-child(2) {
    background-size: 40%;
  }
  .guideBox:nth-child(3) {
    background: #fff url(http://localhost:3000/static/media/guideimg03.c971a1e3cca0a76becd2.png) no-repeat right 90px bottom 50%;
    background-size: 25%;
  }
  .guideBox:nth-child(4) {
    background: #fff url(http://localhost:3000/static/media/guideimg04.31202cfbb40ec6e1fa58.png) no-repeat right 90px bottom 50%;
    background-size: 30%;
  }
  .guideBox:nth-child(5) {
    background: #fff url(http://localhost:3000/static/media/guideimg05.320dbb99f75fbc594d3b.png) no-repeat right 90px bottom 50%;
    background-size: 30%;
  }
  .guideBox:nth-child(6) {
    background: #fff url(http://localhost:3000/static/media/guideimg06.e062588df44f41482a45.png) no-repeat right 90px bottom 50%;
    background-size: 20%;
  }
  /* Story 1024 */
  .tab__content-wrapper ul li .storyImg {
    width: 380px;
    height: 225px;
  }
  .tab__content-wrapper ul li h3 {
    width: 380px;
  }
  .storydetail h2 {
    font-size: 3.2rem;
  }
  .storyConWrap .storydetail {
    width: 95%;
  }
  .storyTxt .storyImg {
    width: 100%;
    height: 50%;
  }
  .storyTxt pre {
    width: 100%;
    margin-top: 10px;
    white-space: pre-wrap;
  }
  /* mypage 1024 */ 
  .mypageWrap .grid {
    grid-template-columns: 1fr 1fr ;
  }
  .mypageWrap .item {
    padding: 30px;
  }
  .mypageWrap .item2Btn {
    bottom: 30px;
  }
  .QRBtnWrap {
    right: 30px;
    bottom: 30px;
  }
  .mypageWrap .item2, .mypageWrap .item3 {
    min-height: 225px;
  }
  .mypageWrap .item h3 {
    font-size: 1.8rem;
  }
  .mypageWrap .item {
    min-width: 0;
  }
  .userLeft .bBox {
    width: 343px;
  }
  /* 체크리스트 */
  .checkListCon .wrap h3 {
    font-size: 1.6rem;
  }
  .checkListCon .wrap select {
    width: 200px;
  }
  .checkListCon .d-flex input[type="radio"] + label {
    width: 130px;
    padding: 10px 40px;
    font-size: 1.4rem;
  }
  .checkListCon .wrap .leftCon,
  .checkListCon .wrap .centerCon,
  .checkListCon .rightCon .sboxWrap {
    padding: 160px 50px;
  }
  .checkListReWrap footer {
    padding: 20px 70px;
  }
  .checkListCon .appuseWrap ul input[type="radio"] + label {
    width: 180px;
    height: 215px;
  }
  .checkListCon .appuseWrap ul label img {
    width: 160px;
    height: 157px;
  }
  .checkListCon .addfunction ul input[type="checkbox"] + label {
    width: 180px;
    height: 144px;
  }
  .checkListCon .addfunction ul label img {
    width: 160px;
    height: 90px;
  }
  .similarWrap li {
    width: 280px;
  }
  .checkListReWrap .stepWrap {
    gap: 10px;
  }
  .checkListReWrap .stepWrap::before {
    width: 83%;
  }
  .checkListReWrap .stepWrap.step2::after {
    left: 10%;
    width: 100px;
  }
  .checkListReWrap .stepWrap.step3::after {
    left: 10%;
    width: 175px;
  }
  .checkListReWrap .stepWrap.step4::after {
    left: 10%;
    width: 265px;
  }
  .checkListReWrap .stepWrap.step5::after {
    left: 10%;
    width: 340px;
  }
  .checkListReWrap .stepWrap.step6::after {
    left: 10%;
    width: 440px;
  }
  .checkListReWrap footer .btnWrap {
    width: auto;
  }
  .checkListReWrap footer .btnWrap button {
    width: 150px !important;
  }
  .leftCon .sbox div input[type="text"],
  .sbox .tit {
    width: 100%;
  }
  /* 팝업 1024 */
  .popupBox {
    min-width: 650px;
  }
  /* 튜토팝업 1024 */
  .tutorialCon {
    width: 700px;
  }
  .tutorialCon .contentsWrap .contents .imgWrap {
    height: auto;
  }
  .tutorialCon .contentsWrap .contents .btnWrap {
    flex-wrap: wrap;
    margin: 0;
  }
  .tutorialCon .contentsWrap .contents .btnWrap button {
    display: inline-flex;
  }
}
@media screen and (max-width: 768px) {
  section {
    padding: 100px 50px;
  }
  section:first-child {
    padding: 150px 50px 100px;
  }
  .sect4Re {
    padding: 0 50px 100px;
  }
  .width14 {
    width: 100%;
    padding: 20px 30px;
  }
  .navbar {
    gap: 15px;
  }
  .navbar_logo {
    width: 70px;
  }
  .navbar_wrap  {
    display: none;
  }
  .navbar_menu {
    display: none;
    margin: 20px 0;
    gap: 40px;
    background: #000;
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: hidden;
  }
  .navbar_menu, .navbar_wrap {
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
  }
  .navbar_menu li {
    /* width: 100%; */
    text-align: center;
  }
  .navbar_icon a {
    width: auto;
    height: auto;
  }
  .navbar_toggleBtn {
    display: block;
  }
  .navbar_menu.active,
  .navbar_icon.active {
    display: flex;
  }
  .footer_top {
    display: block;
  }
  .footer_right {
    display: flex;
    gap: 30px;
  }
  .footer_right div {
    text-align: left;
    margin-bottom: 10px;
  }
  footer {
    padding: 50px 0;
  }
  #topBtn {
    right: 60px;
    bottom: 40px;
   }
  #inquiryBtn{
    right: 60px;
    bottom: 110px;
  }  
  .width14, 
  .sect1Re .subTit, 
  .indicaotr,
  .sect06Re .storyWrap  {
    width: 95%;
  }
  .sect06Re .storyWrap {
    padding: 0 !important;
  }
  .sect1Re .mainTit h2 {
    font-size: 4.8rem;
  }
  .sect1Re h2 + p {
    font-size: 2.4rem;
  }
  .sect1Re .subTit {
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .sect2Re .mainTit h2 {
    font-size: 4rem;
  }
  .animated-title {
    font-size: 3.2rem;
  }
  .sect3Re .mainTit h2,
  .sect05Re .mainTit h2 {
    font-size: 3rem;
  }
  .sect4Re .mainTit h2{
    font-size: 2.8rem;
  }
  .sect2Re h2::before, 
  .sect3Re .mainTit h2::before, 
  .sect05Re .mainTit h2::before {
    font-size: 2rem;
  }
  .sect2Re ul li,
  .sect2Re ul li.left {
    width: 660px;
    padding: 20px 35px;
    margin-bottom: 20px;
  }
  .sect2Re ul li p {
    font-size: 1.8rem;
  }
  .sect2Re ul li img {
    width: 30px;
    height: 30px;
  }
  .sect2Re ul li::after {
    border-width: 15px;
  }
  .animated-title {
    padding: 90px 0;
  }
  .sect3Re ul {
    flex-wrap: wrap;
  }
  .sect2Re ul,
  .sect3Re ul{
    width: 95%;
  }
  .sect2Re ul li, 
  .sect2Re ul li.left,
  .sect3Re ul li {
    width: 100%;
  }
  .sect4Re ul {
    flex-wrap: wrap;
  }
  .sect4Re ul li {
    position: relative;
    padding: 30px;
    width: 100%;
    height: auto;
  }
  .sect4Re ul li h3 {
    text-align: left;
    height: auto;
    margin: 0 0 20px;
  }
  .sect4Re ul li p {
    width: 70%;
    text-align: left;
    margin: 0;
  }
  .sect4Re ul li .xCon .circleImg {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 110px;
    height: 110px;
    margin: 0;
  }
  .sect05Re .xBoxWrap {
    flex-wrap: wrap;
  }
  .sect05Re .xBoxWrap .xBox {
    padding: 30px;
  }
  .storyCon {
    height: auto;
    padding: 35px;
  }
  .storyCon .storyImg {
    height: auto;
  }
  .footer_left .left,
  .footer_left .left .d-flex,
  .footer_bottom {
    flex-wrap: wrap;
  }
  .footer_bottom {
    align-items: flex-start;
  }
  .footer_bottom .d-flex {
    margin-bottom: 10px;
  }
  .emailLogin input[type='text'], 
  .emailLogin input[type='password'],
  .emailLogin button.on,
  .snsLogin,
  .emailLogin .warning {
    width: 450px !important;
  }
  .joinWrap .terms{
    width: 80%;
    margin: 0 auto;
  }
  .inputWrap {
    position: relative;
  }
  .emailLogin .warning {
    top: -10px;
    font-size: 1.4rem;
  }
  .checkboxArea {
    width: 80%;
    margin: 0 auto 10px 0 ;
  }
  .termWrap .d-flex button {
    width: 80%;
    margin: 30px auto;
  }
  .joinWrap h3,
  .joinWrap h4 + input, 
  .inputWrap input,
  .joinWrap h4,
  .joinWrap .sbox p,
  .joinWrap .list  {
    width: 100% !important;
    margin-bottom: 5px ;
  }
  .emailWrap .d-flex {
    width: 100%;
    align-items: center;
  }
  .emailWrap .d-flex .inputWrap {
    width: 80%;
  }
  .emailWrap .d-flex  button{
    width: 20%;
    margin-bottom: 10px;
  }
  .emailWrap .d-flex .inputWrap input {
    width: 100% !important;
  }
  .emailWrap button.resend {
    right: 130px;
  }
  .emailWrap button {
    width: 130px;
    font-size: 1.5rem;
  }
  .joinWrap button.view {
    display: none;
  }
  .emailWrap .d-flex {
    justify-content: center;
  }
  .btnWrap {
    width: 90%;
    margin: 0 auto;
  }
  .btnWrap button {
    width: 100% !important;
  }
  .joinCon {
    width: 95%;
    padding: 0 10px;
  }
  .joinWrap button {
    width: 100%;
  }
  /* 서비스 소개 768 */
  .sectTit {
    width: 100%;
  }
  .serviceInfo .width14 {
    width: 100%;
    padding: 0;
  }
  .whyWrap .grid {
    grid-template-rows: repeat(4, minmax(20%, auto));
    grid-template-columns: repeat(1, 100%);
    grid-template-areas: 
    "b"
    "a"
    "c"
    "d";
    justify-items: center;
  }
  .whyWrap .grid .item img {
    width: 80%;
    margin: 0 auto;
  }
  .additionWrap .tabWrap {
    display: block;
  }
  .additionWrap .tab {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .additionWrap .tab__item {
    width: 200px;
    height: 50px;
    font-size: 1.6rem;
    line-height: 40px;
  }
  .addBox .addTit,
  .addBox .addTit p {
    width: auto;
    min-width: auto;
  }
  .addCon {
    margin-top: 10px;
  }
  .privilegeWrap ul li {
    width: 95%;
    height: auto;
  }
  .container .card .contentBx h2 {
    font-size: 2.4rem;
  }
  .container .card .contentBx .txt {
    padding-top: 5px;
  }
  /* 앱생성가이드 768 */
  .guideBox {
    height: 380px;
    padding: 60px;
    border-radius: 30px;
    box-shadow: 0px 20px 40px rgba(0,0,0,.06);
  }
  .guideCon {
    margin-top: 30px;
  }
  .guideWrap .sectTit h2,
  .guideBox h3 {
    font-size: 2.8rem;
  }
  .guideTit img {
     width: 36px;
  }
  .guideBox .step {
    font-size: 1.4rem;
    bottom: 30px;
    left: 30px;
  }
  .guideBox p {
    margin-top: 10px;
    width: 65%;
  }
  .guideBox p br {
    display: none;
  }
  .guideBox:nth-child(3) {
    background: #fff url(http://localhost:3000/static/media/guideimg03.c971a1e3cca0a76becd2.png) no-repeat right 70px bottom 50%;
    background-size: 25%;
  }
  .guideBox:nth-child(4) {
    background: #fff url(http://localhost:3000/static/media/guideimg04.31202cfbb40ec6e1fa58.png) no-repeat right 70px bottom 50%;
    background-size: 30%;
  }
  .guideBox:nth-child(5) {
    background: #fff url(http://localhost:3000/static/media/guideimg05.320dbb99f75fbc594d3b.png) no-repeat right 70px bottom 50%;
    background-size: 30%;
  }
  .guideBox:nth-child(6) {
    background: #fff url(http://localhost:3000/static/media/guideimg06.e062588df44f41482a45.png) no-repeat right 70px bottom 50%;
    background-size: 20%;
  }
  /* Story 768 */
  .storyWrap {
    padding: 150px 30px 100px !important;
  }
  .storyWrap .width14 {
    padding: 0;
  }
  .storyWrap .sectTit h2 {
    font-size: 3.2rem;
  }
  .storyWrap h2 + p {
    font-size: 1.6rem;
  }
  .searchWrap {
    width: 100%;
    margin-top: 20px;
  }
  .searchWrap input {
    width: 100%;
    min-width: 0;
  }
  .sectTit + .d-flex {
    width: 100%;
  }
  .tab__content-wrapper ul {
    justify-content: space-between;
    gap: 10px;
  }
  .tab__content-wrapper ul a {
    width: 48%;
  }
  .tab__content-wrapper ul li .storyImg {
    width: 100%;
    height: 175px;
    border-radius: 10px;
  }
  .tab__content-wrapper ul li h3 {
    width: 100%;
    margin-top: 5px;
    font-size: 1.8rem;
    line-height: 1.3;
  }
  .tab__content-wrapper ul li .tag {
    margin: 7px 0 0;
    font-size: 1.4rem;
  }
  .storydetail h2 {
    font-size: 2.8rem;
  }
  .storyConWrap .width14 {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .storyConWrap .storydetail {
    padding: 50px;
  }
  /* mypage 768 */ 
  .mypageWrap {
    padding: 150px 20px;
  }
  .mypageWrap .width14 {
    padding: 0;
  }
  .mypageWrap .grid {
    grid-template-areas:
    "a"
    "b"
    "c";
    grid-template-columns : 100%;
  }
  .mypageWrap .item2Con {
    margin: 15px 0;
  }
  .mypageWrap .item2Btn {
    bottom: 30px;
  }
  .mypageWrap .item {
    min-height: 225px;
  }
  .mypageWrap .item1 {
    flex-wrap: wrap;
  }
  .mypageWrap .profile {
    width: 80px;
    height: 80px;
    background-size: 40%;
  }
  .mypageWrap .item2Btn button {
    height: 45px;
  }
  .userInfo {
    width: 100%;
    flex-wrap: wrap;
  }
  .userLeft .bBox,
  .userWrap {
    width: 100%;
  }
  .userRight button {
    margin: 0;
  }
  .white .inputWrap input,
  .userLeft, .userRight {
    width: 100%;
  }
  .userWrap {
    padding: 40px;
  }
  .userWrap .d-flex {
    flex-wrap: wrap;
  }
  .editBtn {
    margin-top: 30px;
  }
  .userWrap h3 {
    font-size: 1.8rem;
  }
  /* 체크리스트 768 */
  .checkListReWrap footer {
    padding: 20px 30px;
  }
  .checkListCon .wrap .leftCon {
    padding: 130px 10px 130px 30px;
  }
  .checkListCon .wrap select {
    width: 160px;
    height: 36px;
    padding: 7px 15px;
    background:  url('/public/img/icon_arrow_downB.png')no-repeat center right 15px;
  }
  .checkListCon .wrap .centerCon,
  .checkListCon .rightCon .sboxWrap {
    padding: 130px 30px;
  }
  .checkListReWrap .stepWrap {
    display: none !important;
  }
  .checkListCon .wrap .centerCon h2,
  .leftCon .sbox h2 {
    font-size: 2.4rem;
  }
  .appuseWrap {
    flex-wrap: wrap;
  }
  .checkListCon .appuseWrap ul {
    gap: 10px;
  }
  .checkListCon .appuseWrap .abox {
    margin-top: 40px;
  }
  .checkListCon .appuseWrap .abox:first-child {
    margin-top: 0px;
  }
  .checkListCon .appuseWrap ul label h4, 
  .checkListCon .addfunction ul label h4 {
    font-size: 1.4rem;
  }
  .checkListCon .appuseWrap ul input[type="radio"] + label {
    width: 145px;
    height: 175px;
    border-radius: 15px;
  }
  .checkListCon .appuseWrap ul label img {
    width: 125px;
    height: 122px;
    border-radius: 10px;
  }
  .checkListCon .addfunction ul {
    gap: 10px;
  }
  .checkListCon .addfunction ul input[type="checkbox"] + label {
    width: 150px;
    height: 114px;
    border-radius: 10px;
  }
  .checkListCon .addfunction ul label img {
    width: 130px;
    height: 65px;
    border-radius: 5px;
  }
  /* 팝업 768 */
  .popupBox {
    min-width: 90%;
    padding: 35px;
  }
  .popupBox h2 {
    font-size: 2rem;
  }
  .toastPop,
  .progress  {
    width: 90%;
    border-radius: 15px;
  }
  /* 튜토팝업 768 */
  .tutorialCon {
    width: 90%;
  }
  /* 튜토팝업 768 */
  .tutorialCon {
    min-height: 500px;
    height: 80%;
  }
  .tutorialCon .contentsWrap .list,
  .tutorialCon .contentsWrap .contents {
    padding: 15px;
  }
  .tutorialCon .contentsWrap .contents h2,
  .tutorialCon .contentsWrap .list h3 {
    font-size: 1.8rem;
  }
  .tutorialCon .contentsWrap .list li {
    font-size: 1.5rem;
    word-break: break-all;
  }
  .tutorialCon .contentsWrap .list li.complete::after {
    right: 5px;
  }
}

@media screen and (max-width: 480px) {
  #topBtn {
   right: 30px;
   bottom: 30px;
  }
  #inquiryBtn{
    right: 30px;
    bottom: 100px;
  }
  .navbar {
    margin-top: 10px;
  }
  .navbar_logo {
    min-width: auto;
  }
  .navbar_logo img {
    width: 100%;
  }
  header .width14 {
    padding: 20px 10px;
  }
  header button {
    font-size: 1.7rem;
  }
  .navbar .d-flex {
    gap: 14px;
  }
  .mHeader .mTop {
    width: 95%;
    margin: 10px auto 0;
    padding: 20px 10px;
  }
  button.simple {
    font-size: 1.5rem;
    padding: 4px 16px;
  }
  section {
    padding: 130px 20px;
    min-height: auto;
  }
  section:first-child {
    padding: 100px 20px;
  }
  .sect4Re {
    padding: 0 20px 100px;
  }
  .sect1Re h2::before {
    font-size: 2.4rem;
    top: -40px;
  }
  .sect1Re .subTit {
    margin: 100px auto 0;
  }
  .sect1Re .mainTit h2 {
    font-size: 4rem;
  }
  .sect1Re h2 + p {
    font-size: 2rem;
  }
  .sect2Re .mainTit h2 {
    font-size: 3.2rem;
  }
  .sect3Re ul li {
    padding: 25px;
  }
  .sect3Re ul li img {
    position: absolute;
    top: 10px;
    width: 80px;
    height: 80px;
  }
  .sect3Re ul li h3{ 
    margin-top: 60px;
    font-size: 1.8rem;
  }
  .sect4Re ul li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .sect4Re ul li h3 {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .sect4Re ul li p {
    width: 100%;
    text-align: center;
  }
  .sect4Re ul li .xCon {
    width: 100%;
  }
  .sect4Re ul li .xCon .circleImg {
    position: static;
    margin: 20px auto;
    transform: translate(0);
  }
  .sect05Re .mainTit h2 + div {
    width: 290px;
  }
  .emailLogin input[type='text'], 
  .emailLogin input[type='password'],
  .emailLogin button.on,
  .snsLogin,
  .emailLogin .warning  {
    width: 330px !important;
  }
  .emailLogin button.on {
    margin-top: 35px;
  }
  .joinWrap .terms{
    width: 95%;
    margin: 0 auto;
  }
  .checkboxArea {
    width: 85%;
    margin: 0 auto 10px 0;
  }
  .termWrap .d-flex button {
    width: 95%;
    margin: 30px auto;
  }
  .joinWrap{
    padding: 50px 0;
  }
  .joinWrap ul {
    gap: 5px;
  }
  .joinWrap ul li {
    width: 80px;
  }
  .joinWrap ul li p{
    font-size: 1.4rem;
    text-align: center;
  }
  .joinWrap ul li div {
    width: 40px;
    height: 40px;
  }
  .joinWrap .list {
    flex-wrap: wrap;
    gap: 30px;
  }
  .emailWrap button.resend {
    right: 100px;
  }
  /* 서비스 소개 480 */
  .container {
    width: 97%;
  }
  .container .card {
    width: 100%;
    height: 270px;
  }
  .additionWrap .tab__item{
    width: 47%;
  }
  .container .card .imgBx img {
    width: 35%;
  }
  .container .card .contentBx{
    height: 60px;
  }
  .container .card:hover .contentBx {
    height: 90px;
  }
  .container .card:after {
    font-size: 9rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .planWrap ul li {
    padding: 20px;
  }
  .privilegeWrap ul li {
    padding: 30px;
  }
  .privilegeWrap ul li .tag {
    width: 110px;
    height: 34px;
    line-height: 32px;
  }
  .privilegeWrap ul li h3 {
    margin: 15px 0 10px;
  }
  .addBox .addTit {
    gap: 10px;
  }
  .addBox .addTit img {
    width: 60px;
    object-fit: contain;
  }
  .addBox .addTit  img {
    width: 80%;
    object-fit: contain;
  }
  .footer_left .content {
    width: 95%;
  }
  /* 앱생성가이드 480 */
  .guideCon  {
    width: 100%;
  }
  .guideBox {
    width: 100%;
    height: 280px;
    padding: 40px;
    border-radius: 30px;
  }
  .guideWrap .width14 {
    width: 100%;
    margin: 0;
  }
  .guideCon {
    margin-top: 20px;
  }
  .guideWrap .sectTit h2, 
  .guideBox h3 {
    font-size: 2.4rem;
  }
  .guideBox p {
    margin-top: 0;
  }
  .guideTit img {
    width: 24px;
  }
  .guideBox .step {
    bottom: 20px;
    left: 20px;
    font-size: 1.2rem;
  }
  .guideBox:nth-child(2) {
    bottom: 32rem;
  }
  .guideBox:nth-child(3) {
    bottom: 28rem;
    background: #fff url(http://localhost:3000/static/media/guideimg03.c971a1e3cca0a76becd2.png) no-repeat right 40px bottom 50%;
    background-size: 20%;
  }
  .guideBox:nth-child(4) {
    bottom: 24rem;
    background: #fff url(http://localhost:3000/static/media/guideimg04.31202cfbb40ec6e1fa58.png) no-repeat right 40px bottom 50%;
    background-size: 25%;
  }
  .guideBox:nth-child(5) {
    bottom: 20rem;
    background: #fff url(http://localhost:3000/static/media/guideimg05.320dbb99f75fbc594d3b.png) no-repeat right 40px bottom 40%;
    background-size: 25%;
  }
  .guideBox:nth-child(6) {
    bottom: 16rem;
    background: #fff url(http://localhost:3000/static/media/guideimg06.e062588df44f41482a45.png) no-repeat right 40px bottom 40%;
    background-size: 15%;
  }
  .guideTit {
    padding-bottom: 20px;
  }
  /* Story 480 */
  .storyWrap .sectTit h2 {
    font-size: 2.4rem;
  }
  .tab__content-wrapper ul a {
    width: 100%;
  }
  .tab__content-wrapper ul li .storyImg {
    height: 235px;
  }
  .searchWrap select {
    padding: 0 10px 0 10px;
    background: rgba(255, 255, 255, 0.1) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABKSURBVHgB7Y2xCQAgDAQzSvZfylHeRsHCpIgPQviDVHnuzIQQ/wAw1nmy8b2zYgBR5JCjGvAokv2eIzR5EuHJgwhXfonw5UI0ZwK6QLyDXplsCgAAAABJRU5ErkJggg==)no-repeat center right 5px;
  }
  .storydetail h2 {
    font-size: 2.4rem;
  }
  .storyConWrap .storydetail {
    padding: 30px;
    border-radius: 10px;
  }
  .storyTxt pre h2 {
    font-size: 1.8rem;
  }
  .storyTxt .storyImg img {
    border-radius: 15px;
  }
  /* mypage 480 */ 
  .mypageWrap {
    padding: 150px 16px;
  }
  .mypageWrap .profile {
    width: 60px;
    height: 60px;
  }
  .mypageWrap .item1{
    gap: 10px;
  }
  .userWrap {
    margin-top: 0;
    padding: 30px;
  }
  /* 체크리스트 468 */
  .checkListReWrap header {
    padding: 20px;
    min-height: 76px;
  }
  .checkListReWrap .back {
    left: 10px;
  }
  .checkListReWrap footer {
    padding: 20px 16px;
  }
  .checkListReWrap footer .btnWrap  {
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }
  .checkListReWrap footer .btnWrap.except {
    width: auto;
    justify-content: flex-end;
  }
  .checkListCon .wrap .rightCon {
    display: none;
  }
  .checkListCon .wrap .leftCon {
    width: 100%;
    padding: 120px 20px;
  }
  .checkListCon .wrap .centerCon {
    padding: 120px 20px;
  }
  .info p {
    width: 350px;
    left: -75px;
    transform: translateY(30px);
    white-space: wrap;
  }
  .info1 p {
    left: -115px;
  }
  .similarWrap li {
    width: 80%;
  }
  .checkListCon .appuseWrap ul input[type="radio"] + label {
    width: 160px;
    height: 195px;
  }
  .checkListCon .appuseWrap ul label img {
    width: 140px;
    height: 137px;
  }
  .checkListCon .addfunction ul input[type="checkbox"] + label {
    width: 150px;
    height: 124px;
    border-radius: 10px;
  }
  .checkListCon .addfunction ul label img {
    width: 130px;
    height: 75px;
    border-radius: 5px;
  }
  .leftCon .comment {
    display: block !important;
  }
  /* 튜토팝업 768 */
  .tutorialCon .contentsWrap .list {
    display: none;
    position: absolute;
    width: 100%;
    margin-top: 40px;
    z-index: 100;
    background: #fff;
  }
  .tutorialCon .contentsWrap .list.on {
    display: block;
  }
  .momenu {
    display: block;
  }
  .contents {
    margin-top: 40px;
  }
  .emailWrap .d-flex button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-58%);
    width: auto;
    height: 36px;
    padding: 4px 10px;
    font-size: 1.4rem;
  }
  .emailWrap .d-flex {
    position: relative;
  }
  .emailWrap .d-flex .inputWrap {
    width: 100%;
  }
  .emailWrap button.resend {
    right: 105px;
  }
  .emailWrap button.resend2 {
    right: 70px;
  } 
  .inputWrap {
    margin-bottom: 0;
  }
  .btnWrap {
    margin: 50px auto;
  }
}
@media screen and (max-width: 380px) {
  button.simple {
    font-size: 1.4rem;
    padding: 4px 14px;
  }
  .btnWrap {
    width: 100%;
    padding: 0 16px;
  }
  .emailWrap button.resend {
    right: 100px;
  }
  .emailWrap button.resend2 {
    right: 60px;
  } 
  .additionWrap .tab__item{
    width: 45%;
  }
  .container .card:hover .imgBx {
    transform: translateY(-10%);
  }
  .container .card:hover .contentBx {
    height: 110px;
  }
  /* 앱생성가이드 380 */
  .guideBox {
    padding: 30px;
    height: 250px;
    border-radius: 15px;
  }
  .guideBox h3 {
    font-size: 2rem;
  }
  .guideBox p {
    font-size: 1.4rem;
  }
  .tab__content-wrapper ul li .storyImg {
    height: 180px;
  }
  .tab__content-wrapper ul li h3 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  /* 체크리스트 380 */
  .checkListReWrap header {
    min-height: 60px;
  }
  .checkListCon .appuseWrap ul input[type="radio"] + label {
    width: 150px;
    height: 185px;
  }
  .checkListCon .appuseWrap ul label img {
    width: 130px;
    height: 127px;
  }
  .checkListCon .wrap .leftCon {
    padding: 100px 20px;
  }
  .checkListCon .wrap .centerCon {
    padding: 100px 20px;
  }
}