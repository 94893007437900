@charset "utf-8";

* {
  /* font-family: "Pretendard", "Righteous", sans-serif; */
  font-family: "Pretendard", sans-serif;
  line-height: 1.5;
  margin: 0;
}
html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  color: var(--mainfont-white-color);
  background-color: var(--background-color);
  /* scroll-behavior: smooth; */
  word-break: keep-all;
  overflow-x: clip; 
}

:root { 
  --background-color : #0f0d1c ;
  --mainfont-black-color : #303030;
  --mainfont-white-color : #faf4f5;
  --subfont-color : #ABB1C2;
  --subfont-gray-color:#888;
  --point-color : #6E81E8;
  --sect-color: #9429FF;
  --checklist-color: #B3B3B3;
  --main-font-size : 1.6rem;
  --title-font-size : 4.8rem;
  --gradient-color : linear-gradient(90deg, rgba(134,183,248,1) 0%, rgba(153,148,245,1) 50%, rgba(206,52,238,1) 100%) ;
}
img, svg {
  vertical-align: middle;
}
.wrap {
  position: relative;
  /* width: 1440px; */
  margin: 0 auto;
}
ol, ul, dl,p {
  margin-bottom: 0;
  list-style: none;
  padding: 0;
}
h2 {
  font-size: var(--title-font-size);
  line-height: 1.2;
}
p {
  font-size: var(--main-font-size);
}
button {
  background: none;
  border: none;
  cursor: pointer;
}
input:focus {
  outline: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none; 
  appearance: none;
  color: var(--mainfont-black-color);
}
input {
  font-family: 'Pretendard',sans-serif;
}
table {
  border-collapse: collapse;
}
/* 공통요소 */
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: start;
}
.justify-content-end {
  justify-content: end;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.gap10 {
  gap: 10px;
}
.gap20 {
  gap: 20px;
}
.gap30 {
  gap: 30px;
}
.gap40 {
  gap: 40px;
}
.gap50 {
  gap: 50px;
}
.gradient {
  background-image: linear-gradient(90deg, rgba(134,183,248,1) 0%, rgba(153,148,245,1) 50%, rgba(206,52,238,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.gradientB {
  background: rgb(176,255,249);
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.bold {
  font-weight: 700;
}
.point {
  color: var(--point-color);
}
.center {
  text-align: center;
}
.cursor {
  cursor: pointer;
}
.pre-wrap {
  white-space: pre-wrap;
}
body.scrollLock {
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
}
.boxWrap3 {
  margin-top: 30px;
  padding: 50px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.2);
  font-size: 1.6rem;
}
/* 스크롤바 */
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-thumb {
  background: #6E81E8; 
  border-radius: 10px;
}
body::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0;
}
/* body */
.width14 {
  width: 1280px;
  margin: 0 auto;
}
.height10 {
  padding: 150px 0;
}
button.simple {
  border: 1px solid var(--mainfont-white-color);
  border-radius: 30px;
  padding: 6px 20px;
  color: var(--mainfont-white-color);
  font-size: 1.6rem;
  font-weight: 700;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
button.simple:hover {
  background: var(--mainfont-white-color);
  color: var(--background-color);
}
/* header */
header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.navbar {
  position: relative;
  margin-top: 30px;
  padding: 20px 0;
}
.navbar_logo {
  cursor: pointer;
}
.navbar_logo img {
  width: 130px;
  height: 100%;
  object-fit: cover;
}
.navbar_logo,
nav .right {
  min-width: 260px;
}
.navbar_menu {
  padding: 0;
  gap: 40px;
}
.navbar_menu li {
  position: relative;
  color: var(--mainfont-white-color);
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
}
.navbar_menu li p {
  font-size: 2rem;
}
.navbar_menu li p::after {
  content:"";
  display:block;
  position:absolute;
  bottom: -2px ;
  width:0;
  height:3px;
  background-color:var(--mainfont-white-color);
  -webkit-transition:0.6s;
  transition:0.6s;
}
.navbar_menu li p:hover::after {
  width:100%;
}
.navbar_icon {
  cursor: pointer;
}
.navbar_icon a{
  width: 30px;
  height: 30px;
}
.navbar_toggleBtn {
  display: none;
  width: 24px;
  height: 24px;
  background: url(/public/img/icon_menuW.png)no-repeat center;
}
.navbar_toggleBtn.active{
  background: url(/public/img/icon_close.png)no-repeat center;
}
header button { 
  font-size: 2rem;
  font-weight: 700;
  color: var(--mainfont-white-color);
}
.navbar_toggleBtn .mHeader {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 120;
  width: 100vw;
  height: 100vh;
  background: #000;
}
.navbar_toggleBtn.active .mHeader {
  display: block !important;
}
.mHeader.active {
  display: block;
}
.mHeader .mTop {
  margin: 30px auto 0;
  padding: 20px 30px;
}
.mMenu {
  margin: 50px auto;
}
.mMenu li {
  padding: 20px 0;
}
.mMenu li p {
  text-align: center;
  font-size: 2.4rem;
  font-weight: 700;
}
/* footer */
footer {
  padding: 100px 0;
  color: var(--mainfont-white-color);
  background: #220745;
} 
.footer_top {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.footer_left .footer_logo {
  width: 120px;
}
.footer_left {
  display: flex;
}
.footer_left .content {
  width: 400px;
  margin-bottom: 20px;
}
.footer_left .content span {
  font-size: var(--main-font-size);
  color: #6C41C8;
}
.footer_left .left {
  display: flex;
  gap: 100px;
}
.footer_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0;
  border-top : 0.5px solid #57379C;
}
.footer_bottom .copy {
  font-size: var(--main-font-size);
  font-weight: 700;
  color: #57379C;
}
.sns {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
.sns div {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.sns div:nth-child(1){
  background: url(/public/img/icon_blog.png)no-repeat center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.sns div:nth-child(2){
  background: url(/public/img/icon_facebook.png)no-repeat center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.sns div:nth-child(3) {
  background: url(/public/img/icon_youtube.png)no-repeat center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.sns div:nth-child(1):hover{
  background: url(/public/img/icon_blogW.png)no-repeat center;
}
.sns div:nth-child(2):hover{
  background: url(/public/img/icon_facebookW.png)no-repeat center;
}
.sns div:nth-child(3):hover{
  background: url(/public/img/icon_youtubeW.png)no-repeat center;
}
/* topBtn */
#topBtn {
  opacity: 0;
  position: fixed;
  right: 100px;
  bottom: 80px;
  width: 60px;
  height: 60px;
  padding: 15px;
  border: 2px solid var(--mainfont-white-color);
  border-radius: 100%;
  cursor: pointer;
  mix-blend-mode: difference;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 30;
}
#topBtn img {
  transition: all 0.5s;
}
#topBtn:hover img, 
#topBtn:focus img {
  transform: scale(1.3);
}
/* inquiryBtn */
#inquiryBtn {
  opacity: 0;
  position: fixed;
  right: 100px;
  bottom: 150px;
  width: 60px;
  height: 60px;
  padding: 15px;
  border-radius: 100%;
  background-color: var(--mainfont-white-color);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  mix-blend-mode: difference;
  cursor: pointer;
  z-index: 31;
}
#inquiryBtn:hover img {
  -webkit-animation: hvr-buzz 1s linear infinite;
  animation: hvr-buzz 1s linear infinite;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
#inquiryBtn::after {
  display: none;
  position: absolute;
  left: 50%;
  top: -30px;
  content: 'Help!';
  width: 50px;
  height: 20px;
  transform: translateX(-50%);
  color: var(--mainfont-black-color);
  background: var(--mainfont-white-color);
  font-weight: 700;
  font-size: 1.2rem;
  transition: all 0.5s;
}
#inquiryBtn:hover::after {
  display: block;
}
@-webkit-keyframes hvr-buzz {
  0% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  50% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
@keyframes hvr-buzz {
  0% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  25% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  50% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
  75% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  100% {
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg);
  }
}
/* loading */
/* .loading {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
}
.loadingCon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loadingCon ul {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}
.loadingCon ul li {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #fff;
  animation: bounce 1.2s cubic-bezier(0.3, 0.01, 0.4, 1) infinite;
}
.loadingCon p {
  font-size: 2rem;
  font-weight: 700;
}
.loadingCon ul li:nth-child(1) {
  animation-delay: -0.4s;
}
.loadingCon ul li:nth-child(2) {
  animation-delay: -0.2s;
}
.loadingCon ul li:nth-child(3){
  animation-delay: 0s;
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
} */
/* sect1 */
section {
  padding: 200px 0;
  min-height: 850px;
}
section:first-child {
  padding: 250px 0 150px;
}
.sect1 .sectTit h2 {
  font-size: 12.8rem;
}
.sectTit h2 + p {
  font-size: 4rem;
  font-weight: 700;
}
.sectTit h2 + p + div {
  font-size: 2.4rem;
  color: var(--subfont-gray-color);
}
.partial {
  width: 450px;
  height: 450px;
  border-radius: 50%;
  box-shadow:
    0 0 20px #fff,
    -20px 0 80px #f0f,
    20px 0 80px #0ff,
    inset 0 0 50px #fff,
    inset 50px 0 80px #f0f,
    inset -50px 0 80px #0ff,
    inset 50px 0 300px #f0f,
    inset -50px 0 300px #0ff;
  animation: pulsate 6s linear infinite;
}
@-webkit-keyframes pulsate {
  50% {
    box-shadow:
      0 0 20px #fff,
      20px 0 80px #f0f,
      -20px 0 80px #0ff,
      inset 0 0 50px #fff,
      inset -50px 0 80px #f0f,
      inset 50px 0 80px #0ff,
      inset -50px 0 300px #f0f,
      inset 50px 0 300px #0ff;
  }
}
@keyframes pulsate {
  50% {
    box-shadow:
      0 0 20px #fff,
      20px 0 80px #f0f,
      -20px 0 80px #0ff,
      inset 0 0 50px #fff,
      inset -50px 0 80px #f0f,
      inset 50px 0 80px #0ff,
      inset -50px 0 300px #f0f,
      inset 50px 0 300px #0ff;
  }
}
/* sect2 */
.sect2 .sectTit h2 {
  font-size: 8rem;
  text-align: center;
}
.animated-title {
  position: relative; 
  width: 100% ;
  max-width:100%; 
  height: auto; 
  padding: 115px 0; 
  overflow-x: hidden; 
  overflow-y: hidden;
  font-size: 5rem; 
  font-weight:700; 
  color: #9429FF;
}
.animated-title .track {
  position: absolute; 
  white-space: nowrap;
  will-change: transform;
  animation: marquee 40s linear infinite; 
  background: #9e3dff1e;
}
.animated-title .track .content {
  padding: 20px 0;
}
@-webkit-keyframes marquee {
  from { 
    -webkit-transform: translateX(0);
    transform: translateX(0); 
  }
  to { 
    -webkit-transform: translateX(-50%); 
    transform: translateX(-50%); 
  }
}
@keyframes marquee {
  from { 
    -webkit-transform: translateX(0); 
    transform: translateX(0); 
  }
  to { 
    -webkit-transform: translateX(-50%); 
    transform: translateX(-50%); 
  }
}
.focus-in-expand {
	-webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

/* sect3 */
.sect3 {
  position: relative;
  background: var(--mainfont-white-color);
}
.sect3 .width14 {
  display: flex;
  align-items: center;
}
.sect3 .sectTit h2{
  color: var(--mainfont-black-color);
  font-weight: 400 !important;
}
.sect3 .sectTit h2::before {
  display: block;
  content: 'OUR BUSINESS';
  font-size: 2rem;
}
.sect3Img {
  position: relative;
  width: 500px;
  height: 500px;
}
.sect3Img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 500px;
  object-fit: cover;
  z-index: 10;
}
.circle1, #circle1 {
  position: absolute;
  left: -130px;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: linear-gradient(90deg, rgb(138, 237, 255) 0%, rgb(197, 194, 255) 50%, rgb(244, 187, 255) 100%);
  transform: rotate(30deg);
  z-index: 7;
  animation: hvr-hang 3s 1s infinite linear;
}
.circle2 {
  position: absolute;
  top: 30px;
  left: -25px;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  background: linear-gradient(90deg, rgb(238, 218, 147) 0%, rgb(238, 184, 148) 50%, rgb(245, 139, 136) 100%);
  /* transform: translate(-50%,-50%) rotate(50deg); */
  z-index: 9;
  animation: hvr-hang 3s 5s infinite linear;
}
.circle3, #circle3 {
  position: absolute;
  right: -150px;
  top: 0;
  /* width: 230px;
  height: 230px; */
  width: 25vmin;
  height: 25vmin;
  border-radius: 100%;
  background: linear-gradient(90deg, rgb(138, 237, 255) 0%, rgb(197, 194, 255) 50%, rgb(244, 187, 255) 100%);
  transform: rotate(240deg);
  z-index: 8;
  animation: hvr-hang 3s 3s infinite linear;
}
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
}
@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
}
@keyframes scale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.4);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes translate-x {
  from {
    transform: translateX(-10vmin);
  }
  to {
    transform: translateX(10vmin);
  }
}
@keyframes translate-y {
  from {
    transform: translateY(-10vmin);
  }
  to {
    transform: translateY(10vmin);
  }
}
/* sect4 */
.sect4 .inner {
  display:flex;
  justify-content: space-between;
  height: 1200px;
}  
.sect4 .inner .sectTit {
  position: -webkit-sticky ;
  position: sticky;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  width: 48%;
  height: 200px;
  z-index: 13;
}
.sect4 .inner .sectTit h2::before {
  display: block;
  content: 'OUR SERVICE';
  font-size: 2rem;
}
.sect4 .inner .sectTit h2 + p {
  font-size: 2.4rem;
}
.sect4Img {
  width: 50%;
  height: 1200px;
}
.sect4Img li {
  width: 380px;
  background: #404040;
  border-radius: 20px;
  padding: 30px;
}
.sect4Img li:nth-child(2) {
  margin-left: 380px;
}
.serviceImg {
  padding: 20px;
}
.serviceImg img {
  transition: all 0.3s;
}
.serviceImg:hover img {
  transform: scale(1.1);
}
.serviceTxt {
  padding: 20px;
}
.serviceTxt h3 {
  font-size: 2rem;
}
/* sect5 */
.sect5 .sectTit {
  text-align: center;
}
.sect5 h2 {
  font-size: 9.6rem;
}
.sect5 h2::before{
  display: block;
  content: 'OUR STORY';
  font-size: 2rem;
}
.sect5 h2 + p {
  font-size: 2rem;
  font-weight: 700;
}
.storyWrap { 
  width: 1440px;
  margin: 20px auto 0;
} 
.storyCon {
  padding: 40px 50px;
  width: 345px;
  height: 420px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  margin: 3px;
  transition: all 0.1s;
}
.storyCon h3 {
  margin-top: 10px;
  font-size: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.storyCon h3 + p {
  margin-top: 5px;
  color: #C8C8C8;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
.storyCon .storyImg {
  width: 250px ;
  height: 140px;
  border-radius: 10px;
  margin: 5px 0;
  overflow: hidden;
}
.storyCon .storyImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 1s;
}
.storyCon:hover {
  background: rgba(255, 255, 255, 0.2);
  outline: 3px solid #6E81E8;
  border: none;
}
.storyCon:hover img  {
  transform: scale(1.1);
}
.indicaotr {
  position: relative;
  width: 1440px;
  margin: 0 auto;
}
.indicaotr .prev {
  width: 45px;
  height: 45px;
  background: url('/public/img/icon_arrow_left.png')no-repeat center;
}
.indicaotr .next {
  position: absolute;
  right: 0;
  width: 45px;
  height: 45px;
  background: url('/public/img/icon_arrow_right.png')no-repeat center;
}
.link {
  position: absolute;
  left: 50%;
  display: inline-block;
  font-size: 2.4rem;
  margin-top: 30px;
  transform: translateX(-50%);
}
.link::after {
  content:"";
  display:block;
  position:absolute;
  bottom:-2px;
  width:0;
  height:3px;
  background-color:#fff;
  transition:0.6s;
}
.link:hover::after {
  width:100%;
}

/* sect1Re */
.sect1Re .mainTit {
  margin-top: 130px;
}
.sect1Re h2 {
  position: relative;
  font-size: 7.2rem;
  text-align: center;
  line-height: 1.35;
}
.sect1Re h2::before {
  content: "AiApp";
  display: block;
  position: absolute;
  left: 50%;
  top: -60px;
  transform: translateX(-50%);
  font-size: 3.6rem;
}
.sect1Re h2 + p {
  margin-top: 20px;
  font-size: 3rem;
  text-align: center;
}
.bg1 {
  color:#fff;
  width: 100vw;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(165deg,rgba(26,28,144,0.5) 0%, rgba(155,44,240,0.6) 5%, rgba(26,28,144,0.5) 30%, rgba(10,11,20,1) 70%);
  /* background: linear-gradient(165deg,#9b2cf0, #000, #000); */
  /* background-size: 400% 400%; */
  /* animation: colorChange 6s ease infinite;  */
}
@keyframes colorChange{
  0%{
      background-position: 0% 50%;
  }
  50%{
      background-position: 100% 50%;
  }
  100%{
      background-position: 0% 50%;
  }
}
.sect1Re .subTit * {
  font-family: "Pretendard", sans-serif;
}
.sect1Re .subTit {
  width: 1440px;
  margin: 200px auto 0;
}
.sect1Re .subTit p {
  font-size: 2.6rem;
}
.sect1Re .subTit span {
  font-size: 1.5rem;
}
/* sect2Re */
.sect2Re h2 {
  position: relative;
  font-size: 6.4rem;
  text-align: center;
}
.sect2Re h2::before {
  content: "PAIN POINT";
  position: absolute;
  left: 50%;
  top: -50px;
  display: block;
  width: 200px;
  transform: translateX(-50%);
  font-size: 3.2rem;
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.sect2Re .mainTit p {
  margin-top: 50px;
  font-size: 3rem;
  text-align: center;
}
.sect2Re .mainTit p span {
  font-weight: 700;
}
.sect2Re ul {
  width: 770px;
  margin: 90px auto;
}
.sect2Re ul li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 770px;
  padding: 50px 60px;
  margin-bottom: 30px;
  border-radius: 20px;
  background: #23252F;
}
.sect2Re ul li p{
  font-size: 2.8rem;
}
.sect2Re ul li::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 20px;
}
.sect2Re ul li.left{
  width: 770px;
}
.sect2Re ul li.left::after {
  top: 100%;
  left: 25%;
  margin-left: -5px;
  border-color: #23252F transparent transparent transparent;
}
.sect2Re ul li.right {
  bottom: 150%;
}
.sect2Re ul li.right::after {
  top: 100%;
  left: 75%;
  margin-left: -5px;
  border-color: #23252F transparent transparent transparent;
}
/* sect3Re */
.bg2 {
  width: 100vw;
  height: 100%;
  background: url(/public/img/sect3bg.png) no-repeat center;
  background-size: cover;
}
.sect3Re .mainTit h2 {
  font-size: 4.5rem;
  text-align: center;
}
.sect3Re .mainTit h2::before {
  content: "SERVICE DIFFERENCES";
  display: block;
  margin-bottom: 15px;
  font-size: 3.5rem;
}
.sect3Re .mainTit h2 + p {
  margin: 60px 0;
  font-size: 2.4rem;
  text-align: center;
}
.sect3Re ul {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 1200px;
  margin: 0 auto;
}
.sect3Re ul li {
  width: 545px;
  height: 200px;
  padding: 35px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
}
.sect3Re ul li h3 {
  font-size: 2.2rem;
  margin-bottom: 5px;
}
.sect3Re ul li h3 span {
  font-size: 1.9rem;
  color: #9429FF;
}
.sect3Re ul li img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.sect3Re .nextarrow {
  display: flex;
  justify-content: center;
  margin: 150px 0 0;
}
/* sect4Re */
.sect4Re {
  padding: 0 0 200px;
}
.sect4Re .mainTit h2 {
  text-align: center;
  font-size: 4rem;
}
.sect4Re ul {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 120px;
}
.sect4Re ul li {
  width: 355px;
  height: 510px;
  padding: 0 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.1);
}
.sect4Re ul li h3 {
  margin: 40px 0;
  font-size: 2.3rem;
  text-align: center;
}
.sect4Re ul li .circleImg {
  width: 175px;
  height: 175px;
  margin: 0 auto;
  border-radius:100%;
  overflow: hidden;
}
.sect4Re ul li .circleImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sect4Re ul li p {
  text-align: center;
  margin: 40px 0;
}
/* sect05Re */
.bg3 {
  width: 100vw;
  height: 100%;
  background: url(/public/img/sect05bg.png) no-repeat center;
  background-size: cover;
}
.sect05Re .mainTit h2 {
  position: relative;
  font-size: 4.5rem;
  line-height: 1.5;
  text-align: center;
}
.sect05Re .mainTit h2::before {
  content: "SERVICE DESCRIPCION";
  position: absolute;
  left: 50%;
  top: -50px;
  display: block;
  width: 360px;
  transform: translateX(-50%);
  font-size: 3.2rem;
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.sect05Re .mainTit h2 + div {
  position: relative;
  width: 360px;
  margin: 100px auto 70px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 200px;
  background-image: linear-gradient(#15171a, #15171a),
  linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.sect05Re .mainTit h2 + div p {
  padding: 13px 35px;
  font-size: 2.8rem;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.sect05Re .xBoxWrap {
  display: flex;
  justify-content: center;
  gap: 35px;
}
.sect05Re .xBoxWrap .xBox {
  width: 550px;
  height: 535px;
  padding: 60px 40px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  background: #2022277b;
}
.sect05Re .xBoxWrap .xBox .appTit {
  position: relative;
  width: 270px;
  margin-top: 40px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 24px;
  background-image: linear-gradient(#15171a, #15171a),
  linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.sect05Re .xBoxWrap .xBox .appTit h3 {
  padding: 13px 35px;
  font-size: 3rem;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.sect05Re .xBoxWrap .xBox img {
  object-fit: contain;
}
.sect05Re .xBoxWrap .xBox .d-flex {
  margin-bottom: 40px;
}
.sect05Re .xBoxWrap .xBox .d-flex p {
  font-size: 1.8rem;
}
.sect05Re .xBoxWrap .xBox ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.sect05Re .xBoxWrap .xBox ul li {
  width: 230px;
  margin-bottom: 25px;
}
.sect05Re .xBoxWrap .xBox ul li h4 {
  margin-bottom: 5px;
  color: #9429FF;
  font-size: 1.6rem;
}
.sect05Re .xBoxWrap .xBox ul li p {
  font-size: 1.4rem;
}
/* sect06Re */
.sect06Re .mainTit h2 {
  width: 300px;
  margin: 0 auto;
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.sect06Re .mainTit p {
  margin: 20px 0 50px;
  text-align: center;
  font-size: 2.6rem;
}
/* 서비스소개 */
.serviceInfo .sectTit h2 {
  text-align: center;
  margin-bottom: 20px;
}
.serviceInfo .sectTit h2 span {
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.serviceInfo .sectTit p {
  font-size: 2rem;
  color: var(--mainfont-white-color);
  text-align: center;
}
.infoWrap .sectTit h2::before {
  display: block;
  content: 'Service Infomation';
  font-size: 2rem;
  margin-bottom: 10px;
}
.infoWrap ul {
  display: flex;
  gap: 16px;
}
.container{
  position: relative;
  margin-top: 30px;
}
.container .card{
  position: relative;
  width: 450px;
  height: 350px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  overflow: hidden;
}
.container .card:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #554e96;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}
.container .card2:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ECA626;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}
.container .card3:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #7ebadd;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}
.container .card:hover:before{
  clip-path: circle(300px at 80% -20%);
}
.container .card:after{
  content: 'Algorithm';
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 12em;
  font-weight: 800;
  font-style: italic;
  color: rgba(170, 96, 255, 0.05)
}
.container .card2:after{
  content: 'Consulting';
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 12em;
  font-weight: 800;
  font-style: italic;
  color: rgba(255, 252, 96, 0.05)
}
.container .card3:after{
  content: 'Platform';
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 12em;
  font-weight: 800;
  font-style: italic;
  color: rgba(96, 191, 255, 0.05)
}
.container .card .imgBx{
  position: absolute;
  top: 38%;
  transform: translateY(-45%);
  z-index: 10000;
  width: 100%;
  height: 200px;
  transition: 0.5s;
}
.container .card:hover .imgBx{
  top: 0%;
  transform: translateY(0%);
}
.container .card .imgBx img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-25deg);
  width: 200px;
}
.container .card .contentBx{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}
.container .card:hover .contentBx{
  height: 120px;
}
.container .card .contentBx h2{
  position: relative;
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
  margin: 0;
}
.container .card .contentBx .txt {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  padding-top: 10px;
  padding-bottom: 0;
  font-size: 1.6rem;
}
.container .card:hover .contentBx .txt{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

/* 서비스소개 - whyWrap */
.whyWrap h2 {
  position: relative;
}
.whyWrap h2::before{
  display: block;
  content: 'Why AiApp?';
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  width: 150px;
  font-size: 2.4rem;
  margin-bottom: 10px;
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.whyWrap h3 {
  font-size: 4rem;
  margin-bottom: 10px;
}
.whyWrap p {
  font-size: 2rem;
}
.whyWrap .grid {
  display: grid;
  grid-template-rows: repeat(2, minmax(50%, auto));
  grid-template-columns: repeat(2, 50%);
  grid-auto-rows: 15rem;
  grid-gap: 2rem;
  margin-top: 50px;
  grid-template-areas: 
  "a b"
  "c d";
}
.item-1 { grid-area: a;}
.item-2 { grid-area: b;}
.item-3 { grid-area: c;}
.item-4 { grid-area: d;}

.whyWrap .grid .item {
  display: flex;
  align-items: center;
}

/* 서비스소개 - planWrap */
.planWrap {
  padding: 150px 0;
  min-height: 400px;
}
.planWrap h2 {
  position: relative;
  max-width: 600px;
  height: 110px;
  margin: 0 auto;
  line-height: 100px;
}
.planWrap ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.planWrap ul li {
  display: inline-block;
  text-align: center;
  padding: 20px 50px;
  font-size: 2rem;
  color: var(--mainfont-white-color);
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  white-space: nowrap;
}
.planWrap ul li:last-child {
  width: 45%;
}

/* 서비스 소개 - privilegeWrap */
.privilegeWrap h2::before {
  display: block;
  content: 'Privilege';
  font-size: 2rem;
  margin-bottom: 10px;
}
.privilegeWrap ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}
.privilegeWrap ul li {
  width: 540px;
  height: 250px;
  padding: 40px 50px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  color: var(--mainfont-white-color);
}
.privilegeWrap ul li .tag {
  width: 120px;
  height: 37px;
  border-radius: 30px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 37px;
  color: var(--mainfont-white-color);
  background: #9429FF;
}
.privilegeWrap ul li h3 {
  margin: 15px 0;
  font-size: 2.4rem;
}
.privilegeWrap ul li p {
  font-size: 1.8rem;
}
.privilegeWrap ul li span {
  font-size: 1.6rem;
  color: var(--subfont-gray-color);
}

/* 서비스소개 - additionWrap */
.additionWrap h2::before {
  display: block;
  content: 'Additional Service';
  font-size: 2rem;
  margin-bottom: 10px;
}
.additionWrap h2 span {
  background: linear-gradient(90deg, rgba(176,255,249,1) 3%, rgba(77,163,251,1) 31%, rgba(119,69,255,1) 60%, rgba(138,55,246,1) 82%, rgba(155,44,240,1) 100%);
  background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}
.additionWrap .addWrap {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 50px;
}
.addBox .addTit {
  display: flex;
  justify-content: space-between;
  min-width: 760px;
  background: rgba(255, 255, 255, 0.1);
  border:  1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 30px;
  gap: 50px;
}
.addBox .addTit h4 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}
.addBox .addTit p {
  width: 500px;
}
.addCon {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 30px;
}
.addCon h5 {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
}
.addCon  .conBox  {
  margin-bottom: 30px;
}
.addCon  .conBox:last-child {
  margin-bottom: 0;
}
.addCon  .conBox p {
  width: 700px;
}
.additionWrap .tabWrap {
  margin-top: 30px;
}
.additionWrap .tab {
  display: flex;
  width: 210px;
  flex-wrap: wrap;
}
.additionWrap .tab__item {
  width: 200px;
  height: 60px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.additionWrap .tab__item.active {
  background: var(--mainfont-white-color);
  color: var(--sect-color);
  outline: 3px solid #9429ffaf;
} 
.additionWrap .tab__item:hover, 
.additionWrap .tab__item:focus {
  background: var(--mainfont-white-color);
  color: var(--sect-color);
  outline: 3px solid #9429ffaf;
}
/* 앱생성가이드 */
.guideWrap * {
  overflow-x: visible;
}
.guideWrap .sectTit {
  text-align: center;
}
.guideWrap .sectTit h2::before {
  display: block;
  content: 'Guide';
  font-size: 2rem;
}
.guideCon {
  position: relative;
  margin-top: 50px;
}
.guideBox {
  position: sticky;
  width: 1440px;
  height: 680px;
  padding: 145px;
  color: var(--mainfont-black-color);
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 27px 104px rgba(0,0,0,.08);
  display: flex;
  align-items: center;
}
.guideBox h3 {
  font-size: 4.8rem;
  font-weight: 700;
}
.guideBox h3 span {
  color: var(--point-color);
}
.guideBox p {
  font-size: 2rem;
}
.guideBox:first-child {
  bottom: 32%;
  z-index: 6;
  background: #fff url(/public/img/guideimg01.png) no-repeat right 0 bottom 0;
}
.guideBox:nth-child(2) {
  bottom: 26%;
  z-index: 5;
  background: #fff url(/public/img/guideimg02.png) no-repeat right 0 bottom 0;
}
.guideBox:nth-child(3) {
  bottom: 20%;
  z-index: 4;
  background: #fff url(/public/img/guideimg03.png) no-repeat right 180px bottom 50%;
}
.guideBox:nth-child(4) {
  bottom: 14%;
  z-index: 3;
  background: #fff url(/public/img/guideimg04.png) no-repeat right 180px bottom 50%;
}
.guideBox:nth-child(5) {
  bottom: 8%;
  z-index: 2;
  background: #fff url(/public/img/guideimg05.png) no-repeat right 180px bottom 50%;
}
.guideBox:nth-child(6) {
  bottom: 2%;
  z-index: 1;
  background: #fff url(/public/img/guideimg06.png) no-repeat right 180px bottom 50%;
}
.guideBox .step {
  position: absolute;
  left: 35px;
  bottom: 35px;
  font-size: 2rem;
  font-weight: 700;
}
/* Story */
.storyWrap h2 + p {
  font-size: 2rem;
  font-weight: 400;
  margin-top: 10px;
}
.storyWrap .d-flex {
  margin-top: 50px;
}
.tab {
  display: flex;
  align-items: center;
  padding: 0rem;
}
.tab__item {
  font-size: 1.4rem;
  padding: 0.6rem 1.3rem;
  margin-right: 1rem;
  border: 1px solid #ddd;
  border-radius: 2rem;
  font-weight: 700;
  cursor: pointer;
}
.tab__item.active {
  display: inline-block;
  border: 1px solid #fff;
  background-color: #fff;
  color: #303030;
}
.tab__content {
  display: none;
}
.tab__content.active {
  display: block;
}
.nodata {
  text-align: center;
  line-height: 200px;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--subfont-gray-color);
}
.total {
  margin: 20px 0;
  font-size: 1.4rem;
  font-weight: 700;
}
.tab__content-wrapper ul {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  flex-wrap: wrap;
  padding: 0;
}
.tab__content-wrapper ul li {
  cursor: pointer;
}
.tab__content-wrapper ul li .storyImg{
  width: 460px;
  height: 270px;
  border-radius: 20px;
  overflow: hidden;
}
.tab__content-wrapper ul li .storyImg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: all 1s;
}
.tab__content-wrapper ul li .tag {
  margin: 7px 0 5px;
  color: #888;
}
.tab__content-wrapper ul li  h3 {
  width: 460px;
  font-size: 2rem;
  text-wrap: wrap;
}
.tab__content-wrapper ul li:hover .storyImg {
  outline: 4px solid #6E81E8;
}
.tab__content-wrapper ul li:hover .storyImg img {
  transform: scale(1.1);
}
/* 검색창 */
.searchWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.searchWrap select {
  padding: 0 10px 0 20px;
  width: 100px;
  height: 53px;
  font-size: 1.4rem;
  background: rgba(255, 255, 255, 0.1) url('/public/img/icon_arrow_downW.png')no-repeat center right 10px;
  border: 0;
  border-radius: 5px 0 0 5px;
  color: #fff;
  cursor: pointer;
}
.searchWrap select option {
  color: #000;
}
.searchWrap input {
  height: 53px;
  padding: 6px 10px;
  color: #fff;
  font-size: 1.4rem;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  min-width: 300px;
}
.searchWrap button {
  width: 50px;
  height: 53px;
  border-radius: 0 5px 5px 0;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
/* Story 상세 */
.storyConWrap .storydetail {
  width: 1000px;
  margin: 0 auto;
  padding: 70px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1);
}
.storydetail h2 {
  margin: 5px 0 20px;
  text-wrap: wrap;
}
.storyTxt pre {
  width: 860px;
  margin: 20px 0;
  white-space: pre-wrap;
}
.storyTxt pre h2 {
  font-size: 2rem;
  font-family: "Pretendard",sans-serif;
}
.storyTxt pre p{
  font-size: 1.6rem;
  font-family: "Pretendard",sans-serif;
}
.storyTxt .storyImg {
  width: 800px;
  height: 400px;
  margin: 0 auto;
}
.storyTxt pre ul li {
  font-size: 1.6rem;
}
.storyTxt .storyImg  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.listBtn button{
  width: 100%;
  height: 56px;
  margin-top: 50px;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}
.listBtn button:hover, 
.listBtn button:focus {
  background: #fff;
  color: var(--mainfont-black-color);
}
.pageWrap ul {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.pageWrap ul li {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 5px;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
}
.pageWrap ul li.on {
  background: #fff;
  color: var(--mainfont-black-color);
}
.pageWrap ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 마이페이지 */
.mypageWrap {
  width: 100%;
  position: relative;
}
.mypageWrap  h2 {
  margin-bottom: 30px;
}
.mypageWrap .grid {
  display: grid;
  gap: 10px;
  grid-template-areas: 
 "a a "
 "b c"
 "d d ";
 grid-template-columns: 2fr 1fr
}
.item1 { grid-area: a;}
.item2 { grid-area: b;}
.item3 { grid-area: c;}
.item4 { grid-area: d;}

.mypageWrap .item {
  min-width: 450px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 50px;
}
.mypageWrap .item h3 {
  font-size: 2rem;
}
.mypageWrap .item p {
  font-family: "Preatendard", sans-serif;
  color: #ABB1C2;
}
.keyvalue {
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.mypageWrap .profile {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.5) url(/public/img/aiapp_logoW.svg)no-repeat center;
  background-size: 75%;
}
.mypageWrap .item1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editBtn {
  border: 1px solid #fff;
  padding: 6px 20px;
  border-radius: 100px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}
.editBtn:hover,
.editBtn:focus {
  background: var(--mainfont-white-color);
  color: var(--mainfont-black-color);
}
.margin0 {
  margin: 0;
}
.mypageWrap .item2,
.mypageWrap .item3 {
  min-height: 280px;
}
.mypageWrap .item2 .d-flex h4 {
  width: 135px;
  font-size: 1.6rem;
}
.mypageWrap .item2 .d-flex .d-flex {
  width: 100%;
}
.mypageWrap .item2Con {
  margin: 25px 0;
}
/* .mypageWrap .item2Btn {
  position: absolute;
  bottom: 40px;
} */
.mypageWrap .item2Btn button{
  width: 150px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
  color: #fff;
  background: #585E6F;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}
.mypageWrap .item2Btn button:hover,
.mypageWrap .item2Btn button:focus {
  background: var(--point-color);
}
.QRWrap {
  width: 120px;
  height: 120px;
  padding: 14px;
  background: #585E6F;
  border-radius: 10px;
  margin-top: 10px;
}
.QRWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.QRWrap.on img{
  opacity: 0;
}
.QRBtnWrap {
  position: absolute;
  right: 50px;
  bottom: 50px;
}
.QRBtn {
  padding: 8px 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.5s;
}
.QRBtn:hover,
.QRBtn:focus,
.mypageWrap .item4 button:hover,
.mypageWrap .item4 button:focus {
  background: var(--mainfont-white-color);
  color: var(--mainfont-black-color);
}
.checklistWrap .QRWrap {
  margin: 0 auto 20px;
}
.mypageWrap .item4 button {
  padding: 8px 20px;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}
.mypageWrap .item4 .inquiryList{
  margin-top: 30px;
  padding: 0;
}
.mypageWrap .item4 .inquiryList li {
  padding: 5px 10px;
  transition: all 0.5s;
  cursor: pointer;
}
.mypageWrap .item4 .inquiryList li:hover,
.mypageWrap .item4 .inquiryList li:focus  { 
  background: #404040;
}
.mypageWrap .item4 .inquiryList li h4 {
  font-size: 1.6rem;
  font-weight: 500;
}
.mypageWrap .item4 .inquiryList li .staus {
  padding: 6px 20px;
  font-size: 1.4rem;
  border: 1px solid #fff;
  border-radius: 20px;
}
.mypageWrap .item4 .inquiryList li .staus.on {
  font-weight: 700;
  background: var(--mainfont-white-color);
  color: var(--mainfont-black-color);
}
.userWrap {
  margin-top: 30px;
  padding: 50px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.1) ;
}
.userWrap h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.userWrap .userInfo {
  margin-top: 30px;
}
.userWrap input[type="file"] {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}
.userWrap label {
  padding: 8px 20px;
  color: #fff;
  font-size: 1.4rem;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.description {
  font-size: 1.4rem;
  color: #C8C8C8;
  margin-top: 15px;
}
.userLeft, .userRight {
  width: 49%;
}
.userLeft div {
  margin-bottom: 10px;
}
.userLeft .bBox {
  width: 600px;
  background: rgba(0, 0, 0, 0.3);
  border: 0;
  padding: 14px 24px;
  border-radius: 10px;
  font-size: 1.6rem;
  color: #c0c0c0;
  font-family: "Pretendard",sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
}
.userRight button {
  display: inline-block;
  width: 100%;
  padding: 14px 24px;
  text-align: left;
  margin-top: 38px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--mainfont-white-color);
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
/* 팝업 */
.popupWrap {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}
.popupBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: 800px;
  max-height: 700px;
  overflow: auto;
  padding: 40px;
  color: var(--mainfont-black-color);
  background: #fff;
  border-radius: 30px;
}
.popupBox::-webkit-scrollbar {
  width: 0;
}
/* .popupBox img {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.closeBtn {
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
  margin: 0 !important;
}
.closeBtn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
  margin: 0 !important;
}
.popupBox h2 {
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--mainfont-black-color);
}
.popupBox h4 {
  margin: 0;
}
.popupBox p {
  font-size: 1.8rem;
  font-weight: 500;
}
.popupWrap div {
  font-family: "Pretendard";
  font-size: 1.6rem;
  font-weight: 600;
  color: #585E6F;
}
.popupBox .gap30 .pbox1 h3 {
  margin-top: 20px;
}
.popupBox .small, .popupBox .gap30 div p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #666;
}
.popupBox div span {
  font-weight: 700;
  color: #6E81E8;
}
.popupBox .gap30 div {
  width: 50%;
}
.popupBox .d-flex div h3 {
  font-size: 1.6rem;
  font-weight: 700;
}
.popupBox .d-flex div img {
  margin-bottom: 20px;
}
/* 토스트팝업 */
.toastPop.on {
  display: block;
}
.toastPop {
  display: none;
  position: fixed;
  bottom: 30px;
  left: 50%;
  z-index: 120;
  transform: translateX(-50%);
  width: 700px;
  min-height: 64px;
  padding: 20px 55px;
  border-radius: 10px;
  background: #303030;
}
.toastPop p {
  font-weight: 700;
}
.toastPop.success {
  background: #303030 url('/public/img/icon_ok.png')no-repeat center left 20px;
}
.toastPop.warning {
  background: #303030 url('/public/img/icon_warning.png')no-repeat center left 20px;
}
.toastPop.warning p {
  color: #fff;
}
.toastPop.error {
  background: #303030 url('/public/img/icon_error.png')no-repeat center left 20px;
}
/* 공유하기 팝업 */
.popupWrap input{
  width: 85%;
  height: 49px;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  font-size: 1.4rem;
  outline: 0;
}
.popupWrap .share button {
  width: 13%;
  height: 48px;
  border: 1px solid #303030;
  border-radius: 0 5px 5px 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  background: #303030;
}
.popupWrap h3 {
  font-size: 1.8rem;
  margin-bottom: 5px;
}
.popupWrap .sBox {
  margin-bottom: 20px;
}
.popupWrap select {
  width: 100%;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 10px 20px;
  font-size: 1.4rem;
  outline: 0;
  background: url('/public/img/icon_arrow_downB.png')no-repeat center right 20px;
}
.popupWrap textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 10px 20px;
  font-size: 1.4rem;
  outline: 0;
}
.popupWrap input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
}
.popupWrap .agreeWrap {
  margin-top: 15px;
}
.img50 {
  width: 50%;
}
.popTit {
  margin-bottom: 30px;
}
.popBtnWrap {
  margin-top: 30px;
}
.popBtnWrap button {
  width: 140px;
  height: 48px;
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 700;
  background: #C8C8C8;
}
.popBtnWrap button.on {
  color: var(--mainfont-white-color);
  background: var(--mainfont-black-color);
}
/* QR팝업 */
.QrPop {
  min-width: 300px;
}
/* 엡생성 체크리스트 */
.checklistWrap h2 {
  margin-top: 60px;
  text-align: center;
}
.ListWrap {
  margin-top: 50px;
}
.ListWrap .ListLeft,
.ListWrap .ListRight {
  width: 50%;
}
.ListWrap  h3 {
  font-size: 2.4rem;
  margin: 10px 0 10px;
}
.ListWrap .Tit {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ListWrap ul {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}
.ListWrap ul label {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 340px;
  height: 120px;
  padding: 30px 20px;
  border-radius: 10px;
  background: #303030;
  cursor: pointer;
  transition: all 0.5s;
}
.ListWrap ul label .img {
  width: 50px;
  height: 50px;
}
.ListWrap ul label .img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ListWrap ul label h4 {
  font-size: 1.6rem;
}
.ListWrap ul label p {
  color: #999;
  font-size: 1.4rem;
  font-weight: 500;
}
.ListWrap ul label:hover,
.ListWrap ul label.on {
  box-shadow: 0 0 0 2px #6E81E8 inset;
}
.ListWrap ul .content {
  width: 200px;
}
.bottomBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
  padding: 0 240px;
}
.bottomBar button {
  width: 175px;
  height: 50px;
  font-size: 2rem;
  font-weight: 700;
  border-radius: 10px;
  color: #fff;
  border: 1px solid #fff;
}
.bottomBar button.on {
  background: #fff;
  color: #303030;
}
.info {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  background: url(/public/img/icon_infoB.png)no-repeat center;
  background-size: cover;
  cursor: pointer;
  transition: all 0.5s;
}
.mypageWrap .info {
  background: url(/public/img/icon_infoW.png)no-repeat center;
}
.info p {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 3;
  white-space: nowrap;
  border: 1px solid #eee;
  background: #fff;
  color: var(--mainfont-black-color);
  padding: 8px 20px;
  border-radius: 5px;
  transition: all 0.5s;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.info:hover p {
  display: block;
}
.leftCon .comment {
  display: none !important;
  background: #F7F9FE;
  padding: 8px 16px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 1.4rem !important;
  color: #888;
}
/* 체크리스트 02 */
.checklistWrap  h2 {
  font-size: 3.2rem;
  margin-bottom: 50px;
}
.similarApp ul {
  flex-wrap: wrap;
}
.similarApp .appIcon {
  width: 80px;
  height: 80px;
  border-radius: 12px;
}
.similarApp .appIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.similarApp li {
  width: 400px;
  height: 120px;
  background: #303030;
  border-radius: 10px;
  padding: 25px;
}
.similarApp li h4 {
  font-size: 1.6rem;
}
.similarApp li p {
  width: 250px;
  color: #999;
  font-size: 1.6rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.moreBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  gap: 10px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 6px 20px;
  margin-top: 50px;
}
/* 체크리스트 03 */
.templateWrap ul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.templateWrap ul li {
  font-size: 1.8rem;
}
.templateWrap .tempImg {
  margin-top: 10px;
  width: 200px;
  height: 352px;
  border-radius: 10px;
  background: #303030;
}
.templateWrap .tempImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  width : 20px;
  height : 20px;
  border : 1px solid #fff;
  border-radius : 18px;
  padding-top : 1px;
}
input[type="radio"]:checked {
  background: url(/public/img/circleW.png)no-repeat center;
}
/* 체크리스트 04 */
.addFunctWrap ul {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-size: 1.6rem;
}
.addFunctWrap .AddFuncImg {
  width: 200px;
  height: 110px;
  border-radius: 10px;
  background: #303030;
  margin-top: 10px;
}
.addFunctWrap .AddFuncImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 체크리스트 05 */
.checklistWrap h2 + p {
  text-align: center;
  font-weight: 500;
  color: #ccc;
}
.createWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px; 
} 
.createWrap .left {
  width: 425px;
  height: 481px;
  background: url('/public/img/phone.png')no-repeat;
}
.createWrap .right {
  min-width: 700px;
  padding: 30px;
  border-radius: 20px;
  background: #303030;
}
.createWrap .right h3 {
  font-size: 1.8rem;
}
.createWrap .right p {
  color: #ccc;
}
.createWrap .right input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  font-size: 1.4rem;
  color: #fff;
  background: #272727;
  border: 0;
}
.infoBtn {
  font-size: 1.4rem;
  color: #fff;
  text-decoration: underline;
}
/* 앱생성 완료 */
.checklistWrap .Tit {
  margin-bottom: 30px;
}
.Tit h2 {
  margin: 50px 0 10px;
}
.FAQWrap {
  width: 560px;
  margin: 0 auto;
  background: #303030;
  padding: 30px;
  border-radius: 20px;
}
.FAQWrap h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.FAQWrap ul li {
  margin: 0 0 5px 20px;
  font-size: 1.6rem;
  color: #ccc;
  list-style: disc;
  cursor: pointer;
  transition: all 0.5s;
}
.FAQWrap ul li:hover {
  color: #fff;
  text-decoration: underline;
}
.testInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  font-weight: 700;
}
.testInfo button {
  padding: 8px 20px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #000;
  background: #fff;
  border-radius: 30px;
  transition: all 0.5s;
}
.height5 {
  padding: 100px 0 50px;
}
.testInfo button:hover {
  background: #000;
  color: #fff;
  outline: 1px solid white;
  outline-offset: 0px;
}
/* 1:1 문의 */
.inquiryWrap {
  margin-top: 50px;
  width: 100%;
}
.inBoxWrap {
  display: flex;
  gap: 20px;
}
.inquiryWrap .inBoxWrap .inBox {
  width: 50%;
}
.inquiryWrap .inBox {
  width: 100%;
}
.inquiryWrap .inBox h3 {
  margin: 20px 0 10px;
  font-size: 1.8rem;
  font-weight: 700;
}
.inquiryWrap .inBox span {
  margin-top: 5px;
  font-size: 1.4rem;
}
.inquiryWrap select {
  width: 100%;
  height: 56px;
  padding: 17px 20px;
  font-size: 1.4rem;
  color: #fff;
  border: 0;
  border-radius: 5px;
  outline: none;
  background: #313131 url('/public/img/icon_arrow_downW.png')no-repeat center right 25px;
}
.inquiryWrap input {
  width: 100%;
  height: 56px;
  padding: 17px 20px;
  font-size: 1.4rem;
  color: #fff;
  background: #313131;
  border: 0;
  border-radius: 5px;
}
input[type=file]::file-selector-button {
  width: 120px;
  height: 25px;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  background: none;
  cursor: pointer;
}
/* .inquiryWrap label {
  display: inline-block;
  width: 100%;
  height: 56px;
  padding: 17px 20px;
  font-size: 1.4rem;
  color: #fff;
  background: #313131;
  border: 0;
  border-radius: 5px;
} */
.inquiryWrap textarea {
  width: 100%;
  min-height: 250px;
  padding: 20px;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 5px;
  background: #313131;
  border: 0;
  outline: 0;
}
.BtnWrap {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.Btn {
  width: 200px;
  padding: 8px 25px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  font-size: 1.6rem;
  font-weight: 700;
  transition: all 0.5s;
}
.Btn:hover, 
.Btn:focus {
  color: #000;
  background: #fff;
}
/* 내 문의 */
.inquiryWrap.user {
  padding: 50px;
  border-radius: 20px;
  background: #303030;
}
.inquiryWrap.user input, 
.inquiryWrap.user textarea ,
.inquiryWrap.user select {
  background: #222;
}
.inquiryWrap.user h2 {
  font-size: 2rem;
}
.inquiryWrap.user .inBox div {
  width: 100%;
  height: 56px;
  padding: 17px 20px;
  font-size: 1.4rem;
  color: #fff;
  background: #222;
  border-radius: 5px;
}
.inquiryWrap.user .answer {
  width: 100%;
  min-height: 250px;
}
/* login */
.loginWrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginWrap .loginCon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* .loginWrap .loginCon p {
  margin-bottom: 10px;
} */
/* .loginWrap .checkboxArea {
  margin-bottom: 20px;
} */
.checkboxArea p,
.checkboxArea input {
  margin: 0 !important;
}
.snsLogin {
  width: 400px;
  height: 45px;
  margin-top: 10px;
  font-size: 1.4rem;
  color: #757575;
  background: #fff url("/public/img/Google.png")no-repeat center left 20px;
  border-radius: 5px;
  text-align: center;
}
.emailLogin {
  margin-top: 30px;
}
.emailLogin input[type='text'],
.emailLogin input[type='password'] {
  display: block;
  width: 500px !important;
  height: 48px;
  padding: 14px 16px;
  /* margin-bottom: 10px; */
  border: none;
  border-radius: 5px;
  font-size: 1.4rem;
  color: var(--mainfont-black-color);
  background: #fff;
}
.emailLogin .find {
  margin-bottom: 30px;
}
.checkboxArea label{
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.checkboxArea label input {
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none; 
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 3px;
  margin-right: 8px;
}
.checkboxArea label input:checked {
  border: 1px solid #6E81E8;
  background: var(--point-color) url(/public/img/checked.png)no-repeat center;  
  background-size: cover;
}
.emailLogin .d-flex p {
  margin: 0;
}
.emailLogin button.on {
  width: 500px;
  height: 48px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--mainfont-white-color);
  background: var(--point-color);
}
.emailLogin button {
  margin-top: 10px;
  font-size: 1.4rem;
  color: var(--subfont-gray-color);
  transition: all 0.5s;
}
.emailLogin button:hover {
  color: var(--mainfont-white-color);
}
.snsLoginWrap {
  margin-top: 30px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 500px #fff inset; 
  -webkit-text-fill-color: #303030 
}
.emailLogin .inputWrap:nth-child(3),
.emailLogin .inputWrap:nth-child(4),
.emailLogin .inputWrap:nth-child(5) {
  display: inline;
}
.emailLogin .warning {
  position: absolute;
  text-align: left;
}
.emailLogin button.on {
  margin-top: 20px;
}
/* 가입절차 - 약관동의 */
.joinWrap {
  display: flex;
  justify-content: center;
  padding: 100px 0;
  min-height: 1000px;
}
.joinWrap  h1 {
  font-size: 4rem;
  text-align: center;
}
.joinWrap ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0 50px;
}
.joinWrap ul li {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 95px;
}
.joinWrap ul li div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  font-weight: 700;
  border: 1px solid #fff;
  border-radius: 100px;
}
.joinWrap ul li div.on {
  color: var(--background-color);
  background: var(--mainfont-white-color);
  border: 1px solid #000;
}
.joinWrap ul li.etc {
  width: 24px;
  height: 24px;
  background: url("/public/img/icon_arrow_rightW.png")no-repeat;
}
.joinWrap .all {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 700;
}
.termWrap {
  display: flex;
  justify-content: center;
}
.joinWrap .terms {
  width: 600px;
  height: 250px;
  margin: 10px 0 20px;
  padding: 20px;
  overflow: auto;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 1.4rem;
  color: var(--mainfont-white-color);
  background: rgba(255, 255, 255, 0.1);
  font-family: "Pretendard", sans-serif;
}
.joinWrap .terms::-webkit-scrollbar {
  width: 0;
}
.joinWrap .terms::-webkit-scrollbar-thumb {
  background: #000; 
  border-radius: 10px;
}
.joinWrap .terms::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0;
}
.joinWrap button {
  width: 600px;
  height: 48px;
  margin: 0 auto;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 5px;
  color: var(--mainfont-white-color);
  background: var(--point-color);
}
/* 가입절차 - 추가정보입력 */
.joinWrap h3 {
  font-size: 2rem;
}
.joinWrap .list {
  justify-content: flex-start;
  gap: 40px;
  margin: 20px 0 20px;
}
.joinWrap .list li {
  width: auto;
}
.joinWrap .list li label{
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.joinWrap h4 {
  font-size: 1.6rem;
  margin-bottom: 5px;
}
.joinWrap .sbox {
  position: relative;
  margin: 20px 0;
}
.joinWrap .sbox p {
  color: var(--subfont-gray-color);
}
.joinWrap h4 + input,
.inputWrap input {
  width: 600px !important;
  height: 48px;
  padding: 14px 16px;
  border: none;
  border-radius: 5px;
  font-size: 1.4rem;
  background: #fff;
  color: var(--mainfont-black-color);
}
.white .inputWrap input {
  width: 600px !important;
  height: 48px;
  padding: 14px 16px;
  border: none;
  border-radius: 5px;
  font-size: 1.4rem;
  background: rgba(255, 255, 255, 0.1);
  color: var(--mainfont-white-color);
}
.white .inputWrap input:focus  {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.emailWrap input {
  width: 440px !important;
  height: 48px;
  /* margin: 5px 0; */
  padding: 14px 16px;
  border: none;
  border-radius: 5px;
  font-size: 1.4rem;
  background: #fff;
  color: var(--mainfont-black-color);
}
.emailWrap button {
  width: 150px;
  /* margin: 5px 0; */
}
.emailWrap div {
  position: relative;
}
.emailWrap button.resend {
  position: absolute;
  right: 130px;
  text-decoration: underline;
  background: none;
  color: var(--subfont-gray-color);
}
.joinWrap button.view {
  position: absolute;
  right: 20px;
  top: 42px;
  width: 24px;
  height: 24px;
  background: url("/public/img/view.png");
}
.joinWrap button.view:focus {
  outline: none;
}
.joinWrap button.off {
  background: url("/public/img/viewoff.png");
}
.btnWrap {
  display: flex;
  gap: 10px;
  margin-top: 50px;
}
.btnWrap button {
  width: 295px;
}
.btnWrap button.off {
  width: 295px;
  background: transparent;
  border: 1px solid #6E81E8;
}
.warning {
  color: #f83927 !important;
}
.joincomplete {
  margin: 100px 0;
}
.joinWrap h2 {
  margin-bottom: 10px;
  font-size: 2.4rem;
  text-align: center;
}
.joinWrap h2 + p {
  font-size: 1.6rem;
  text-align: center;
}
.inputWrap {
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.emailWrap .inputWrap:nth-child(3),
.emailWrap .inputWrap:nth-child(4),
.emailWrap .inputWrap:nth-child(5) {
  display: inline;
}
.emailWrap {
  margin-bottom: 30px !important;
}
.warning {
  font-size: 1.6rem;
}
.emailWrap .warning {
  position: absolute !important;
}
span.f14 {
  display: inline-block;
  font-size: 1.4rem;
  margin-bottom: 5px;
}

/* 아이디&비밀번호 찾기 */
.or {
  margin-top: 100px;
}
.or p {
  margin-bottom: 10px;
}
.or button {
  border: 1px solid #6E81E8;
  color: var(--point-color);
  background: none;
}
.joinWrap .result {
  width: 600px;
  margin: 30px 0 50px;
  padding: 30px 100px;
  border-radius: 5px;
  background: var(--mainfont-black-color);
  text-align: center;
}
.joinWrap .result p {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--subfont-gray-color);
  margin-bottom: 20px;
}
.joinWrap .result div {
  font-size: 2.4rem;
  font-weight: 700;
}
.joinWrap .mt50 {
  margin-top: 50px;
}
.joinWrap .nodata {
  color: #fff;
}
/* 생성앱리스트 */
.AdWrap {
  margin-top: 30px;
}
.slick-slide {	
  padding-right: 10px;
}
.AdBox {
  position: relative;
  width: 480px;
  height: 280px;
  border-radius: 20px;
  cursor: pointer;
}
.fBox {
  position: absolute;
  right: 25px;
  top: 25px;
  padding: 8px 12px;
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 5px;
  z-index: 40;
}
.AdBox01 .AdBg {
  position: relative;
}
.AdBox01 .AdBg div {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); 
}
.AdBox01 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.AdBox01 .AdCon {
  position: absolute;
  left: 25px;
  bottom: 25px;
}
.AdBox .AdTxt h2 {
  font-size: 1.6rem;
  color: #303030;
  margin-bottom: 5px;
}
.AdBox01 .AdTxt p {
  width: 425px;
  font-size: 1.6rem;
  color: #303030;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.AdBox01 h3 {
  font-size: 1.6rem;
  font-weight: 700;
}
.AdBox01 .AdApp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: #303030;
}
.AdBox01 .AdApp .sBox{
  display: flex;
  align-items: center;
  gap: 15px;
}
.AdBox01 .AdApp .AdAppImg {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
}
.AdBox01 .AdApp .AdAppImg img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.AdBox01 .AdApp button {
  width: 110px;
  height: 40px;
  font-size: 1.4rem;
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 5px;
  transition: all 0.5s;
}
.AdBox01 .AdApp button:hover {
  background: rgba(0, 0, 0, 0.5);
}
.AdBox02 {
  position: relative;
}
.AdBox02 .AdCon {
  position: absolute;
  left: 25px;
  bottom: 25px;
}
.AdBox02 .AdTxt p {
  width: 425px;
  font-size: 1.6rem;
  color: #303030;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.rankWrap,
.newWrap {
  position: relative;
  margin-top: 30px;
}
.rankWrap h2, 
.newWrap h2 {
  font-size: 2.4rem;
}
.rankWrap button,
.newWrap button {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  width: 120px;
}
.rankWrap .listWrap h3  {
  font-size: 1.8rem;
}
.rankWrap .listWrap .listCon {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.rankWrap .listWrap .d-flex {
  width: 32%;
}
.rankWrap .listWrap span {
  font-size: 2rem;
  font-weight: 700;
}
.rankWrap .listWrap img {
  width: 72px;
  height: 72px;
  border-radius: 10px;
  object-fit: cover;
}
.rankWrap .listWrap p {
  font-size: 1.4rem;
}
.listWrap .star  {
  font-size: 1.2rem;
  align-items: center;
  gap: 5px;
}
.listWrap .star img {
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.rankWrap .indicaotr {
  position: absolute;
  top: 50%;
  z-index: 60;
}
.rankWrap .indicaotr .prev {
  position: absolute;
  left: -60px;
  width: 40px;
  height: 40px;
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url('/public/img/icon_arrow_leftW.png')no-repeat center;
  background-size: 24px 24px;
  transition: all 0.5s;
}
.rankWrap .indicaotr .prev:hover {
  background: #fff url('/public/img/icon_arrow_leftB.png')no-repeat center;
}
.rankWrap .indicaotr .next {
  position: absolute;
  right: -60px;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 100px;
  background: url('/public/img/icon_arrow_rightW.png')no-repeat center;
  background-size: 24px 24px;
  transition: all 0.5s;
}
.rankWrap .indicaotr .next:hover {
  background: #fff url('/public/img/icon_arrow_rightB.png')no-repeat center;
}
.newWrap {
  margin-top: 80px;
}
.newWrap .newimg {
  width: 120px;
  height: 120px;
  border-radius: 12px;
}
.newWrap .newimg img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
}
.newWrap .listCon {
  margin-top: 20px;
  cursor: pointer;
}
.newWrap .listCon h3 {
  font-size: 1.8rem;
}
.appList {
  margin-top: 30px;
}
.appList li {
  margin-bottom: 20px;
  cursor: pointer;
}
.appList h3 {
  font-size: 1.8rem;
}
.appList p {
  font-size: 1.4rem;
}
.appList img {
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 10px;
}
.appList .star  {
  width: 16px;
  height: 16px;
  font-size: 1.2rem;
  align-items: center;
  gap: 5px;
}
.appList .star img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.appWrap .appImg {
  width: 150px;
  height: 150px;
  border-radius: 20px;
}
.appWrap .appImg img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.appWrap .appImg iframe {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.appWrap .appTit {
  display: flex;
  gap: 20px;
  align-items: center;
}
.appWrap .appTit h2 {
  font-size: 4rem;
  margin-bottom: 10px;
}
.appWrap .appTit .star {
  font-size: 1.6rem;
}
.appWrap .appTit .star img {
  width: 24px;
  height: 24px;
}
.appWrap .appBtn {
  margin: 20px 0;
}
.appWrap .appBtn button {
  display: flex;
  gap: 5px;
  padding: 8px 20px;
  background: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 30px;
}
.appWrap .appcontImg {
  width: 1048px;
}
.appWrap .appcontImg li{
  width: 200px;
  height: 352px;
  margin-right: 10px;
  border-radius: 10px;
  background: #666;
  cursor: pointer;
}
.appWrap .appcontImg li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.appInfo {
  margin: 30px 0 20px;
  padding: 30px;
  background: #303030;
  border-radius: 20px;
}
.appReview {
  padding: 30px;
  background: #303030;
  border-radius: 20px;
}
.appInfo h2, 
.appReview h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.appInfo div, 
.appReview div {
  font-size: 1.6rem;
  color: #ddd;
}
.userbox .userTit {
  margin-bottom: 5px;
}
.userbox .profile {
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 100%;
}
.userbox .profile + div {
  font-weight: 700;
}
.userbox .date {
  font-size: 1.4rem;
}
.addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;
}
/* 체크리스트 수정 */
.checkListReWrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #fff;
}
.checkListReWrap header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  min-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.checkListReWrap .back{
  position: absolute;
  left: 40px;
  top: 50%;
  display: flex;
  align-items: center;
  gap: 8px;
  transform: translateY(-50%);
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--mainfont-black-color);
}
.checkListReWrap .stepWrap {
  position: relative;
  display: flex;
  gap: 180px;
}
.checkListReWrap .stepWrap::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  display: block;
  width: 93%;
  height: 1px;
  border: 1px dashed #B3B3B3;
}
.checkListReWrap .stepWrap li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80px;
}
.checkListReWrap .stepWrap li div {
  margin: 0 13px 5px;
  width: 25px;
  height: 25px;
  background: #fff;
  border: 2px solid #B3B3B3;
  border-radius: 50px;
}
.checkListReWrap .stepWrap li h2 {
  font-size: 1.4rem;
  color: var(--checklist-color);
}
.checkListReWrap .stepWrap li.on div {
  border: 2px solid #6E81E8;
  box-shadow: 0px 0px 8px #6e80e8bb;
}
.checkListReWrap .stepWrap li.on h2 {
  color: var(--point-color);
}
.checkListReWrap .stepWrap.step2::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 3%;
  z-index: -1;
  display: block;
  width: 260px;
  height: 1px;
  border: 1px solid #6E81E8;
}
.checkListReWrap .stepWrap.step3::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 3%;
  z-index: -1;
  display: block;
  width: 530px;
  height: 1px;
  border: 1px solid #6E81E8;
}
.checkListReWrap .stepWrap.step4::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 3%;
  z-index: -1;
  display: block;
  width: 790px;
  height: 1px;
  border: 1px solid #6E81E8;
}
.checkListReWrap .stepWrap.step5::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 3%;
  z-index: -1;
  display: block;
  width: 1050px;
  height: 1px;
  border: 1px solid #6E81E8;
}
.checkListReWrap .stepWrap.step6::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 3%;
  z-index: -1;
  display: block;
  width: 1310px;
  height: 1px;
  border: 1px solid #6E81E8;
}
.checkListReWrap footer {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #eee;
  padding: 20px 140px;
  background: #fff;
}
.checkListReWrap footer .btnWrap {
  margin: 0;
}
.checkListReWrap footer .btnWrap button {
  width: 150px;
  height: 48px;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 5px;
  background: var(--point-color);
  color: var(--mainfont-white-color);
}
.checkListReWrap footer .btnWrap button.off {
  border: 1px solid #6E81E8;
  color: var(--point-color);
  background: #fff;
}
.checkListCon .wrap {
  display: flex;
  width: 100%;
  color: var(--mainfont-black-color);
  background: #fff;
}
.checkListCon .wrap .sbox {
  margin-bottom: 20px;
}
.checkListCon .wrap .leftCon {
  width: 50%;
  padding: 180px 150px;
}
.checkListCon .wrap .centerCon {
  width: 100%;
  padding: 150px 150px;
}
.checkListCon .wrap .centerCon.complete {
  margin-top: 150px;
}
.centerCon.complete .btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  gap: 100px;
}
.centerCon.complete .btnWrap button{
  display: block;
  width: auto;
  border: 0;
  font-size: 2rem;
  font-weight: 700;
  transition: all 0.5s;
}
.centerCon.complete .btnWrap button::after {
  content: '';
  display: block;
  width: 0%;
  height: 2px;
  background: var(--point-color);
  transition: all 0.5s;
}
.centerCon.complete .btnWrap button:hover {
  color: var(--point-color);
}
.centerCon.complete .btnWrap button:hover::after {
  width: 100%;
}
.checkListCon .wrap .rightCon {
  width: 50%;
  height: 100vh;
  background: #F5FAFF;
  position: fixed;
  right: 0;
}
.checkListCon .wrap .tit {
  gap: 8px;
  margin-bottom: 10px;
}
.checkListCon .wrap h3 {
  font-size: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp:  2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Pretendard", sans-serif;
}
.checkListCon .wrap select {
  width: 240px;
  height: 40px;
  padding: 9px 15px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  outline: none;
  font-size: 1.4rem;
  background:  url('/public/img/icon_arrow_downB.png')no-repeat center right 20px;
}
.checkListCon .wrap select option {
  font-family: "Pretendard", sans-serif;
}
.checkListCon input[type="radio"]{
  width: 0;
  height: 0;
  border: 0;
}
.checkListCon .d-flex input[type="radio"] + label {
  width: 150px;
  height: 40px;
  padding: 10px 50px;
  margin-right: 10px;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  cursor: pointer;
}
.checkListCon .d-flex input[type="radio"]:checked + label {
  color: var(--mainfont-white-color);
  background: var(--mainfont-black-color);
}
.checkListCon .wrap .centerCon h2 {
  margin-bottom: 20px;
  font-size: 3.2rem;
  line-height: 1.3;
  text-align: center;
}
.checkListCon .appuseWrap {
  display: flex;
  justify-content: center;
}
.checkListCon .appuseWrap .abox {
  width: 750px;
}
.checkListCon .appuseWrap ul  {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.checkListCon .appuseWrap ul input[type="radio"] + label {
  display: inline-block;
  width: 210px;
  height: 245px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #eee;
  cursor: pointer;
}
.checkListCon .appuseWrap ul label img {
  width: 190px;
  height: 187px;
  border-radius: 15px;
  outline: 1px solid #eee;
  margin-bottom: 10px;
  overflow: hidden;
  object-fit: cover;
}
.checkListCon .addfunction input {
  -webkit-appearance: none;
  appearance: none;
}
.checkListCon .appuseWrap ul label h4,
.checkListCon .addfunction ul label h4 {
  margin-left: 5px;
  font-size: 1.6rem;
  font-family: "Pretendard", sans-serif;
}
.checkListCon .appuseWrap ul input[type="radio"]:checked + label,
.checkListCon .appuseWrap ul input[type="checkbox"]:checked + label {
  outline: 2px solid #6E81E8;
  outline-offset: -2px;
  box-shadow: 0 0 4px #6e80e8b7;
}
.checkListCon .addfunction ul  {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.checkListCon .addfunction ul input[type="checkbox"] + label {
  display: inline-block;
  width: 210px;
  height: 174px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #eee;
  cursor: pointer;
}
.checkListCon .addfunction ul label img {
  width: 190px;
  height: 120px;
  border-radius: 15px;
  outline: 1px solid #eee;
  margin-bottom: 10px;
  overflow: hidden;
}
.checkListCon .addfunction ul input[type="checkbox"]:checked + label {
  outline: 2px solid #6E81E8;
  outline-offset: -2px;
  box-shadow: 0 0 4px #6e80e8b7;
}
.similarWrap {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.similarWrap li {
  position: relative;
  width: 400px;
  min-height: 407px;
  padding: 30px;
  border-radius: 20px;
  background: #F5FAFF;
  text-align: center;
}
.similarWrap li img {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border: 1px solid #eee;
  border-radius: 12px;
  margin:0 auto 10px;
}
.similarWrap li p {
  margin: 10px 0;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp:  7;
  line-clamp: 7;
  -webkit-box-orient: vertical;
  font-family: "Pretendard", sans-serif;
}
.similarWrap li button {
  text-decoration: underline;
  font-size: 1.6rem;
  color: var(--point-color);
}
.similarWrap li button + img {
  width: 24px;
  height: 24px;
  border: none;
  margin: 0;
}
.similarWrap li .link {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 25px;
}
.checkListCon .moreBtn {
  margin: 30px auto 0;
  font-weight: 700;
  border: 1px solid #303030;
  color: var(--mainfont-black-color) !important;
}
.leftCon .sbox h2 {
  font-size: 3.2rem;
}
.leftCon .sbox div h3 {
  margin: 30px 0 5px;
}
.leftCon .sbox div input[type="text"] {
  width: 470px;
  height: 48px;
  padding: 13px 16px;
  border: 1px solid #eee;
  border-radius: 10px;
  font-size: 1.4rem;
}
.checkListCon .description {
  margin-top: 5px;
  color: var(--subfont-gray-color);
}
.rightCon .sboxWrap {
  height: 100vh;
  padding: 180px 80px;
  overflow-y: auto;
}
.rightCon .sboxWrap::-webkit-scrollbar {
  width: 0px;
}
.rightCon .sboxWrap::-webkit-scrollbar-thumb {
  width: 0px;
}
.rightCon .sboxWrap::-webkit-scrollbar-track {
  width: 0px;
}
.rightCon .sbox p,
.rightCon .sbox p span {
  margin-bottom: 5px;
  font-family: 'Pretendard', sans-serif;
}
.rightCon .sbox img{
  width: 60%;
}
.templateImg {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 40;
  transform: translate(-50%, -50%);
  border: 10px solid #fff;
  border-radius: 38px;
  width: 285px;
  height: 595px;
  background: #fff;
  box-shadow: 0 0 20px #0000003d;
}
.templateImg .slick-slider {
  width: 265px;
  height: 575px;
  border: 1px solid #eee;
  border-radius: 32px;
  overflow: auto;
}
.templateImg .slick-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.templateImg .slick-slider::-webkit-scrollbar {
  width: 0;
}
.test-css{
  list-style: none;
  text-align: end;
  position: fixed;
  padding: 0;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
}
.test-css li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  /*margin: 1px;*/
  padding: 0;
  cursor: pointer;
}
.test-css li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.test-css li button:before {
  font-size: 2.7rem;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .75;
  color: var(--subfont-color);
}
.test-css li.slick-active button:before {
  opacity: .75;
  color: var(--point-color);
}
.centerCon h2 + p {
  color: var(--subfont-gray-color);
  font-weight: 500;
  text-align: center;
  font-family: 'Pretendard', sans-serif;
}
.centerCon .QRWrap {
  width: 160px;
  height: 160px;
  margin: 20px auto;
  padding: 18px;
  background: #F5FAFF;
}
.centerCon .QRBtn {
  border: 1px solid #303030;
  color: var(--mainfont-black-color);
  margin-bottom: 40px;
}
.centerCon .FAQWrap {
  width: 560px;
  margin: 150px auto 0;
  padding: 30px;
  border-radius: 20px;
  background: #F5FAFF;
  color: var(--mainfont-black-color);
}
.centerCon .h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.centerCon .FAQWrap ul li {
  margin: 0 0 5px 20px;
  font-size: 1.6rem;
  color: var(--mainfont-black-color);
  list-style: disc;
  cursor: pointer;
  transition: all 0.5s;
}
.centerCon .FAQWrap li:hover {
  color: var(--point-color);
  text-decoration: underline;
}
.custom {
  justify-content: flex-start !important;
  margin-top: 20px;
}
.leftCon span {
  font-size: 1.6rem;
  font-weight: 700;
  color: var(--point-color);
}
.leftCon  h2 + p {
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--subfont-gray-color);
}
.sbox .tit {
  width: 470px;
}
.sbox .tit button {
  margin: 0;
  font-size: 1.4rem;
  text-decoration: underline;
}
.sbox .tit h3 {
  margin: 0 !important;
}
/* 튜토리얼 팝업 */
.tutorialPopWrap.on {
  display: block;
}
.tutorialPopWrap {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 102;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}
.tutorialCon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 900px;
  height: 600px;
  background: #fff;
  border-radius: 30px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: 'Pretendard', sans-serif !important;
}
.tutorialCon .top {
  padding: 20px;
  border-radius: 30px 30px 0 0;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.tutorialCon .top h2 {
  font-size: 2rem;
  color: var(--mainfont-black-color);
}
.tutorialCon .contentsWrap {
  display: flex;
  height: 100%;
  color: var(--mainfont-black-color);
}
.tutorialCon .contentsWrap .list {
  width: 300px;
  height: 530px;
  padding: 20px;
  border-radius: 0 0 0 30px;
  border-right: 1px solid #eee;
  overflow-y: auto;
}
.tutorialCon .contentsWrap .list::-webkit-scrollbar {
  width: 5px;
}
.tutorialCon .contentsWrap .list::-webkit-scrollbar-thumb {
  width: 5px;
  background: #DBE1FF;
  border-radius: 100px;
}
.tutorialCon .contentsWrap .list h3 {
  margin-top: 15px;
  font-size: 2rem;
  font-weight: 700;
}
.tutorialCon .contentsWrap .list div:first-child h3{
  margin: 0;
}
.tutorialCon .contentsWrap .list h4 {
  margin: 10px 0 5px 10px;
  font-size: 1.7rem;
  font-weight: 600;
  font-family: "Pretendard", sans-serif;
}
.tutorialCon .contentsWrap .list li {
  position: relative;
  padding: 6px 20px 6px 15px;
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--subfont-gray-color);
  cursor: pointer;
}
.tutorialCon .contentsWrap .list li.on {
  color: var(--point-color);
  background: #F5FAFF;
  border-radius: 10px;
}
.tutorialCon .contentsWrap .list li.complete::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 120;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: url("/public/img/icon_complete.png")no-repeat center;
}
.tutorialCon .contentsWrap .contents {
  width: 600px;
  height: 100%;
  padding: 20px;
  border-radius: 0 0 30px 0;
}
.tutorialCon .contentsWrap .contents .imgWrap {
  width: 100%;
  height: 230px;
}
.tutorialCon .contentsWrap .contents h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.tutorialCon .contentsWrap .contents .textWrap {
  margin: 10px 0 20px;
}
.tutorialCon .contentsWrap .contents p {
  font-size: 1.6rem;
  color: var(--subfont-gray-color);
}
.tutorialCon .contentsWrap .contents .btnWrap {
  margin-top: 0;
}
.tutorialCon .contentsWrap .contents .btnWrap button {
  display: flex;
  gap: 8px;
  align-items: center;
  width: auto;
  padding: 6px 20px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #fff;
  background: var(--point-color);
  border-radius: 5px;
}
.tutorialCon .contentsWrap .contents .btnWrap button.off {
  color: var(--subfont-gray-color);
  background: none;
  border: none;
}
.momenu {
  display: none;
  position: absolute;
  z-index: 101;
  width: 100%;
  padding: 6px 20px;
  font-size: 1.6rem;
  border-bottom: 1px solid #eee;
  border-radius: 5px;
  background: #fff url('/public/img/icon_arrow_downB.png')no-repeat center right 15px;
  color: var(--point-color);
  text-align: left;
}
/* 기획서 */
.appplanWrap * {
  font-family: "Pretendard", sans-serif;
  border-collapse : collapse;
}
.appplanWrap {
  position: relative;
  background: #fff;
}
.sidebarWrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  gap: 0;
  width: 230px;
  height: 100vh;
  padding: 20px;
  background: #303030;
}
.sidebarWrap .d-flex {
  flex-wrap: wrap;
}
.sidebarWrap .sidebar .d-flex {
  flex-wrap: nowrap;
}
.sidebarWrap  .sidebar {
  width: 100%;
}
.sidebarWrap button.back {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}
.sidebarWrap button.guideBtn {
  width: 100%;
  padding: 6px 20px;
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
}
.sidebarBtn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sidebarBtn button{
  display: inline-block;
  width: 90%;
  padding: 8px 20px;
  background: #fff;
  color: #303030;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 100px;
  margin-top: 5px;
}
.sidebarWrap button.pdfBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
  background: none;
}
.sidebarWrap h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.sidebarWrap ul {
  margin-bottom: 30px;
}
.sidebarWrap .pageList {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebarWrap ul::-webkit-scrollbar,
.pdfCon .appImg::-webkit-scrollbar {
  width: 3px;
}
.sidebarWrap ul::-webkit-scrollbar-thumb,
.pdfCon .appImg::-webkit-scrollbar-thumb {
  background: #cecece; 
  border-radius: 10px;
}
.sidebarWrap ul::-webkit-scrollbar-track,
.pdfCon .appImg::-webkit-scrollbar-track{
  background: transparent;
  opacity: 0;
}
.sidebarWrap ul li {
  width: 100%;
  height: 36px;
  padding: 4px 15px;
  margin-bottom: 5px;
  font-size: 1.5rem;
  line-height: 2.8rem;
  background: #212121;
  border-radius: 5px;
  cursor: pointer;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp:1;
  line-clamp:1;
}
.sidebarWrap ul li.on {
  outline: 2px solid #6E81E8;
  outline-offset: -3px;
}
.tab_content {
  display: none;
  width: 100%;
  height: 100vh;
  margin-left: 12vw;
  padding: 30px 70px;
  color: #303030;
}
.tab_content.active {
  display: block;
}
.tab_content .tit {
  margin-bottom: 8px;
  font-size: 1.8rem;
  font-weight: 700;
}
.tab_content .appImg {
  width: 20vw;
  height: 80vh;
  border: 1px solid #eee;
  border-radius: 12px;
  overflow-y: auto;
}
.tab_content .appImg::-webkit-scrollbar,
.tab_content .sbox::-webkit-scrollbar,
.tab_content .appcodeWrap::-webkit-scrollbar {
  width: 3px;
}
.tab_content .appImg::-webkit-scrollbar-thumb,
.tab_content .sbox::-webkit-scrollbar-thumb,
.tab_content .appcodeWrap::-webkit-scrollbar-thumb {
  background: #cecece; 
  border-radius: 10px;
}
.tab_content .appImg::-webkit-scrollbar-track,
.tab_content .sbox::-webkit-scrollbar-track,
.tab_content .appcodeWrap::-webkit-scrollbar-track {
  background: transparent;
  opacity: 0;
}
.tab_content .appImg img {
  width: 100%;
}
.tab_content .appImg iframe {
  height: 100%;
  width: 100%;
}
.tab_content button.codeBtn{
  padding: 6px 20px;
  font-size: 1.4rem;
  border-radius: 100px;
  color: #fff;
  background: #303030;
}
.tab_content .sbox {
  width: 22vw;
  height: 80vh;
  padding: 20px;
  margin-bottom: 20px;
  resize: none;
  border: none;
  border-radius: 12px;
  font-size: 1.6rem;
  background: #F6F6F6;
}
.tab_content2 .sbox {
  width: 25vw;
  height: 80vh;
  padding: 20px;
  margin-bottom: 20px;
  resize: none;
  border: none;
  border-radius: 12px;
  font-size: 1.6rem;
  background: #F6F6F6;
}
.tab_content .sbox:focus {
  border: none;
  outline: none;
}
textarea::placeholder {
  color:#999;
}
input::placeholder {
  color: #aaa;
}
.copyBtn {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #999;
  font-size: 1.4rem;
}
.tab_item2 button {
  gap: 4px;
  padding: 6px 20px;
  border-radius: 100px;
  font-size: 1.4rem;
  color: #fff;
  background: #B8B8B8;
}
.tab_item2.on button {
  background: #303030;
}
.tab_content2 {
  display: none;
}
.tab_content2.active {
  display: block;
}
.tab_content2 .appcodeWrap {
  width: 30vw;
  height: 80vh;
  padding: 20px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #ECECEC;
  background: #303030;
  border-radius: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}
.tab_content2 .appcodeWrap .top {
  width: 100%;
}
.tab_content2 .appcodeWrap .top div button {
  padding: 6px 20px;
  border-radius: 100px;
  background: #fff;
  font-weight: 700;
  color: #303030;
}
.tab_content2 .appcodeWrap .top div span {
  margin-left: 10px;
  color: #999;
}
.tab_content2 .appcodeWrap .appcode {
  margin-top: 10px;
  width: 100%;
  height: auto;
  word-wrap: break-word;
  color: #fff;
  background: #303030;
  border: none;
  resize: none;
}
.tab_content2 .appcodeWrap .appcode:focus {
  border: none;
  outline: none;
}
.appplanWrap .bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 73px;
  border: 1px solid #eee;
  background: #fff;
}
.appplanWrap .bottom button {
  height: 37px;
  margin-right: 5%;
  padding: 8px 40px;
  background: #303030;
  border-radius: 100px;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
}
.tooltip {
  position: relative;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 700px;
  background-color: #fff;
  color: #666666;
  text-align: left;
  border-radius: 30px;
  padding: 30px;
  position: absolute;
  z-index: 1;
  font-size: 1.6rem;
  font-weight: 400;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.tooltip .tooltiptext h2 {
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: 700;
  color: #303030;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 20px;
}
.tooltip .tooltip-bottom {
  width: 700px;
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip .tooltip-bottom::after {
  bottom: 100%;
  left: 53.5%;
  margin-left: -5px;
  border-color: transparent transparent #fff transparent;
}
/* 개발 문서 */
.topBar {
  display: flex;
  align-items: center;
  position: fixed;
  left: 230px;
  top: 0;
  z-index: 50;
  width: 100%;
  height: 65px;
  padding-left: 10px;
  border-bottom: 1px solid #eee;
  background: #fff;
}
.topBar .breadcrumbs {
  display: flex;
  align-items: center;
  gap: 5px;
}
.topBar button {
  font-size: 1.4rem;
  font-weight: 600;
  color: #767676;
}
.topBar .searchWrap {
  width: 600px;
  margin-left: 15%;
  padding: 10px  16px;
  border: 1px solid #bbb;
  border-radius: 100px;
}
.topBar .searchWrap input {
  width: 100%;
  height: auto;
  padding: 0 16px;
  background: #fff;
  color: #303030;
}
.D-sheet {
  position: fixed;
  left: 230px;
  top: 65px;
  width: 230px;
  height: 100%;
  padding: 20px;
  background: #F7F9FE;
  color: #303030;
}
.D-sheet h2 {
  font-size: 1.6rem;
  padding-top: 5px;
}
.D-sheet li {
  cursor: pointer;
}
.D-sheet li ul li {
  display: flex;
  align-items: center;
  padding: 3px 5px 3px 24px;
  font-size: 1.4rem;
  background: url("/public/img/icon_arrow_rightG.png")no-repeat center left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  transition: all 0.3s;
}
.D-sheet li ul li:hover,
.D-sheet li ul li.on {
  background: #E8EBFB url("/public/img/icon_arrow_rightG.png")no-repeat center left;
  border-radius: 3px;
}
.D-content {
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin: 65px 30px 0 460px;
  padding: 30px;
  color: #303030;
  font-size: 1.4rem;
}
.D-content h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.D-content table {
  width: 700px;
}
.D-content table th,
.D-content table td {
  padding: 4px 10px;
  text-align: left;
}
.D-content table th {
  border: 1px solid #c0c0c0;
  border-bottom: 2px solid #767676;
}
.D-content table td{
  border: 1px solid #c0c0c0;
}
.D-box {
  padding: 30px 0;
  border-bottom: 1px solid #303030;
}
.D-box:last-child {
  border: none;
}
.D-box h4 {
  font-size: 1.6rem;
  margin: 10px 0 5px;
}
.D-box p {
  font-size: 1.6rem;
  color: #666;
}
.D-box .D-link {
  position: static;
  width: 800px;
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 0 0 8px 0;
  padding: 12px 0 12px 20px;
  font-size: 1.6rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateX(0);
  cursor: pointer;
}
.D-box .D-link::before {
  display: block;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  z-index: 15;
  width: 10px;
  height: 100%;
  border-radius: 5px 0 0 5px;
  background: #c0c0c0;
  transition: all 0.5s;
}
.D-box .D-link:hover::before {
  background: #6E81E8;
} 
.D-box .D-link:last-child {
  margin: 0;
}

/* PDF */
.pdfWrap {
  position: relative;
  width: 100%;
  height: 100vh;
  color: var(--mainfont-black-color);
  background: #fff;
}
.pdfWrap * {
  font-family: "Pretendard", sans-serif !important;
}
.pdfWrap .copy {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--subfont-gray-color);
}
.pdfWrap .cover {
  width: 100%;
  height: 70%;
  padding-left: 100px;
  background: var(--point-color);
}
.pdfWrap .cover h1 {
  position: absolute;
  top: 50%;
  font-size: 9.6rem;
  line-height: 1.2;
  transform: translateY(-50%);
  color: #fff;
}
.pdfWrap .cover h1 span {
  font-size: 4.8rem;
}
.pdfWrap .cover p {
  position: absolute;
  bottom: 23%;
  font-size: 2.4rem;
  color: var(--mainfont-black-color);
}
.pdfHead  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  padding: 30px 0 10px;
  border-bottom: 1px solid #ababab;
}
.pdfHead h1{
  font-size: 2rem;
}
.pdfHead div {
  font-size: 1.6rem;
  font-weight: 700;
}
.pdfCon {
  padding: 30px;
}
.pdfCon .history {
  width: 100%;
}
.pdfCon .history th {
  padding: 10px 30px;
  background: #E8EBEB;
  font-size: 1.6rem;
  border: 1px solid #B6B6B6;
  border-top: none;
}
.pdfCon .history td {
  padding: 10px 15px;
  text-align: center;
  font-size: 1.6rem;
  border: 1px solid #B6B6B6;
}
.pdfCon .history th:first-child,
.pdfCon .history td:first-child {
  border-left: none;
}
.pdfCon .history th:last-child,
.pdfCon .history td:last-child {
  border-right: none;
}
.pdfCon .history th:nth-child(3) {
  width: 50%;
}
.pdfCon .history td:nth-child(3) {
  width: 50%;
  text-align: left;
}
.pdfCon .screenflowImg {
  margin: 30px auto 0;
  width: 1400px;
  height: 700px;
}
.pdfCon .screenflowImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pdfCon .flow th {
  padding: 10px 30px;
  font-size: 1.6rem;
  color: #fff;
  border: 1px solid #B6B6B6;
  background: var(--mainfont-black-color);
}
.pdfCon .flow td {
  padding: 10px 30px;
  border: 1px solid #B6B6B6;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
}
.pdfCon h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.pdfCon .flowchartImg {
  width: 1000px;
  height: 650px;
}
.pdfCon .flowchartImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pdfCon .appImg {
  width: 317px;
  height: 685px;
  margin-top: 35px;
  border: 1px solid #eee;
  border-radius: 12px;
  overflow-y: auto;
}
.pdfCon .appImg img {
  width: 100%;
}
.pdfCon .appImg iframe {
  width: 100%;
}
.pdfCon .d-flex div h2 + div {
  width: 500px;
  height: 685px;
  padding: 20px;
  font-size: 1.6rem;
  background: #F6F6F6;
  border-radius: 10px;
  overflow-y: auto;
}
.pdfCon .d-flex div:last-child h2 + div {
  width: 700px;
}
.pdfCon .plan {
  padding: 0 50px;
}

/* 앱생성 프로세스바 */
.progress.on {
  display: block;
}
.progress {
  display: none;
  position: fixed;
  left: 50%;
  bottom: 50px;
  z-index: 55;
  width: 500px;
  height: 110px;
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transform: translateX(-50%);
}
.progress p {
  font-weight: 700;
  color: var(--mainfont-black-color);
}
.progress .progress-bar {
  width: 100%;
  height: 25px;
  margin-top: 18px;
  border-radius: 20px;
  background: #F5FAFF;
}
#progress {
  appearance: none;
}
#progress::-webkit-progress-bar {
  background: #F5FAFF;
  border-radius: 20px;
  border: 1px solid #F5FAFF;
  height: 25px;
  width: 100%;
  overflow: hidden;
}
#progress::-webkit-progress-value {
  background: var(--point-color);
  border-radius: 0px;
  height: 20px;
  width: 48px;
  transition: all 0.5s;
}
.progress .bar span {
  display: inline-block;
  height: 20px;
  background: var(--point-color);
  border-radius: 20px;
  transition: all 0.5s;
}
.progress img {
  animation: spin 1s infinite ease-in-out;
}
.copyBtn {
  display: inline-flex;
  justify-content: center;
  position: relative;
  font-weight: 500;
  text-decoration: none;
  border: none;
  cursor: pointer;
  line-height: 1.4;
  border-radius: 8px;
  box-sizing: border-box;
  transition: box-shadow .2s, background-color .2s, color .2s, border .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  white-space: nowrap;
}
.copyBtn-sm {
  font-size: 14px;
  padding: 6px 12px;
}
.copyBtn-primary.copyBtn-filled {
  background-color: var(--button-primary);
  color: var(--white);
}
.copyBtn-filled {
  color: var(--gray-100);
}
.ml-2 {
  margin-left: .5rem;
}
.copyBtn-label-wrap {
  opacity: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.copyBtn-node {
  display: flex;
}
.copyBtn-label-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* error404 */
.error404{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.error404 h2 {
  font-size: 5rem;
  font-weight: 700;
}
.error404 p {
  margin: 2rem 0;
}
.error404 button {
  padding: 1rem 3rem;
  border: 1px solid var(--mainfont-white-color);
  border-radius: 100px;
  color: var(--mainfont-white-color);
  font-size: 1.6rem;
}

/* Loading 로딩 */
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 101;
}
.loader-wrap{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 25px;
  z-index: 102;
}
.loader{
  display: flex;
  height: 100px;
  width: 100px;
}
.loader-item{
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
}
.loader-3{
  background: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  animation: loader 1s cubic-bezier(.41,.25,.32,.83) infinite;
}
.loader-3:nth-of-type(2){
  animation: loader 1s cubic-bezier(.41,.25,.32,.83)  .25s infinite;
}
.loader-3:nth-of-type(3){
  animation: loader 1s cubic-bezier(.41,.25,.32,.83) .5s  infinite;
}
@keyframes loader {
  0%{ opacity: .25; }
  100%{ opacity: 1; }
}

/* AI 생성 로딩 화면 */
.generating-screen {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generating-content {
  text-align: center;
}

.ai-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  animation: bounce 2s infinite;
}

.generating-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generating-text .title {
  color: #303030;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.dots {
  display: flex;
  gap: 6px;
}

.dots span {
  width: 8px;
  height: 8px;
  background: #6E81E8;
  border-radius: 50%;
  animation: fade 1.4s ease-in-out infinite;
}

.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fade {
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
}

.sbox.pre-wrap {
  max-height: 750px; /* 또는 원하는 높이 */
  overflow-y: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-break: break-all;
}

/* 스크롤바 스타일링 (선택사항) */
.sbox.pre-wrap::-webkit-scrollbar {
  width: 8px;
}

.sbox.pre-wrap::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.sbox.pre-wrap::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.sbox.pre-wrap::-webkit-scrollbar-thumb:hover {
  background: #555;
}